import React, { useEffect } from "react";
import { connect } from "formik";

const flat = (object, path = "") => {
  let names = [];
  for (const key in object) {
    const value = object[key];
    if (typeof value === "string") {
      names.push(`${path}${key}`);
    } else {
      // eslint-disable-next-line no-loop-func
      value.forEach((item, index) => {
        const aa = flat(item, `${path}${key}[${index}].`);
        names = [...names, ...aa];
      });
    }
  }
  return names;
};

export const scrollAndFocusInput = (selector) => {
  const offsetTop = selector.getBoundingClientRect().top;
  if (offsetTop > 0 && offsetTop < 105) {
    window.scrollTo(0, offsetTop + window.scrollY - 200);
  }
  selector.focus();
};

const FocusError = ({ formik }) => {
  const { isSubmitting, errors } = formik;

  console.log('isSubmitting', isSubmitting);
  useEffect(() => {
    const keys = flat(errors);
    if (keys.length > 0) {
      const errorElement = document.querySelector(`[id="${keys[0]}"]`);
      scrollAndFocusInput(errorElement);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return <></>;
};

export default connect(FocusError);
