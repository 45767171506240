import { useState, useEffect } from "react";
import FormikInputColor from "../../formik/components/FormikInputColor";
import RobolButton from "../RobolButton";
import AvatarUpload from "./AvatarUpload";
import CardWrapper from "./CardWrapper";
import ChatbotSkeleton from "./ChatbotSkeleton";
import defaultAvatarChatbox from "../../assets/whitebluelogo.png";
import Tooltip from "../../helpers/Tooltip";
import { TooltipContent } from "../../helpers/Enums";
import { fieldLabels } from "../../helpers/jsonData";

const LayoutCard = ({
  initialValues,
  setDesignValues,
  currentManualStep,
  onSubmit,
}) => {
  const [temporaryValues, setTemporaryValues] = useState(initialValues);
  const [base64Image, setBase64Image] = useState(initialValues.avatar || "");
  const [base64CompanyIcon, setBase64CompanyIcon] = useState(initialValues.company_icon || "");

  const chatBoxAvatar = initialValues.avatarChatBox
    ? initialValues.avatarChatBox
    : defaultAvatarChatbox;

  useEffect(() => {
    // Update design values dynamically
    setDesignValues((prevValues) => ({
      ...prevValues,
      ...temporaryValues,
    }));
  }, [temporaryValues, setDesignValues]);

  useEffect(() => {
    // Set initial images
    if (initialValues.avatar) {
      setBase64Image(initialValues.avatar);
    }
    if (initialValues.company_icon) {
      setBase64CompanyIcon(initialValues.company_icon);
    }
  }, [initialValues]);

  const handleColorChange = (color, fieldName) => {
    console.log("fieldName", fieldName);
    console.log("color", color);
    setTemporaryValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [fieldName]: color.hex,
      };
      console.log("updatedValues", updatedValues);
      return updatedValues;
    });
  };

  const handleDesignConfirm = (fieldName, color) => {
    setTemporaryValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        [fieldName]: color.hex,
      };
      console.log("updatedValues after confirm", updatedValues);
      return updatedValues;
    });
  };

  const handleSave = () => {
    const layoutConfig = {
      color_primary: temporaryValues.color_primary,
      color_secondary: temporaryValues.color_secondary,
      avatar: base64Image, // Uwzględniamy avatar w formacie Base64
      color_button: temporaryValues.color_button,
      color_tertiary: temporaryValues.color_tertiary,
      company_icon: base64CompanyIcon, // Uwzględniamy logo firmy w formacie Base64
    };
    onSubmit(layoutConfig);
  };

  const renderInputFields = () => {
    return Object.keys(initialValues)
      .filter(
        (key) =>
          key !== "isTouched" &&
          key !== "isSaved" &&
          key !== "id" &&
          key !== "company_icon" &&
          key !== "font_family" &&
          key !== "avatar"
      )
      .map((key) => {
        console.log("key", key);
        return (
          <div className="mt-2" key={key}>
            <FormikInputColor
              name={key}
              label={fieldLabels[key] || key} 
              value={temporaryValues[key]}
              onChange={(color) => handleColorChange(color, key)}
              onConfirm={(color) => handleDesignConfirm(key, color)}
            />
          </div>
        );
      });
  };

  return (
    <>
      <div className="row">
        <div className="block-margin"></div>
        <div className="col pb-5">
          <CardWrapper minHeight="70vh" className="mb-2">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h5 className="intelligence-cards-title d-flex align-items-center">
                  {currentManualStep}
                  <div className="ml-2">
                    <Tooltip toolTip={TooltipContent.COLORS} />
                  </div>
                </h5>
              </div>
            </div>
            <hr className="black" />
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 mb-5">
                  <div className="row">
                    <div className="col-lg-6">
                      {renderInputFields()}
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <AvatarUpload
                            setBase64Image={setBase64Image}
                            setDesignValues={setDesignValues}
                            avatarUploadText={"Avatar van je bot"}
                            name="avatar"
                            initialImage={base64Image} // Przekazujemy początkowy obraz
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <AvatarUpload
                            setBase64Image={setBase64CompanyIcon}
                            setDesignValues={setDesignValues}
                            avatarUploadText={"Logo van je bedrijf"}
                            name="company_icon"
                            initialImage={base64CompanyIcon} // Przekazujemy początkowy obraz
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <ChatbotSkeleton
                        primaryColor={temporaryValues.color_primary}
                        secondaryColor={temporaryValues.color_secondary}
                        tertiaryColor={temporaryValues.color_tertiary}
                        buttonColor={temporaryValues.color_button}
                        avatarChatBot={base64Image} // Ensure the avatar is passed correctly
                      />
                      <div className="chatbot-container robolCss mt-3">
                        <span className="chatBtn open">
                          <img
                            src={chatBoxAvatar}
                            width="55px"
                            height="55px"
                            alt="icon"
                            style={{ borderRadius: "50%" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex justify-content-start mt-1">
                      <RobolButton onClick={handleSave} theme="dark">
                        Ontwerp opslaan
                      </RobolButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardWrapper>
        </div>
      </div>
    </>
  );
};

export default LayoutCard;
