import React from "react";
import { useNavigate } from "react-router-dom";
import "../../css/PrivateRoutes/AdminDashboardCards.css";
import RobolButton from "../RobolButton";

const EditEmployeeCard = ({
  employeeName,

  gradient,
  profilePic,
  functionTitle,
  employeeID,
  available,
  role,
}) => {
  const gradientStyle = {
    background: `linear-gradient(180deg, ${gradient.start} 0%, ${gradient.end} 100%)`,
  };

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="edit-emp-card">
      <div className="card">
        <div className="card-img-top" style={gradientStyle}>
          <img
            src={
              profilePic.startsWith("data:image")
                ? profilePic
                : `data:image/jpeg;base64,${profilePic}`
            }
            alt={`${employeeName} profile`}
            className="profile-pic"
          />
          {/* <img
            src={getProfilePicSrc(profilePic)}
            alt={`${employeeName} profile`}
            className="profile-pic"
          /> */}
        </div>
        <div className="card-body">
          {available && <div className="availability-indicator"></div>}
          {!available && <div className="not-availabile-indicator"></div>}
          <h5 className="card-title text-center mt-5">{employeeName}</h5>
          <p className="card-text text-center">
            {role === "ADMIN" ? "ADMIN" : functionTitle}
          </p>
          <div className="buttons-container d-flex justify-content-around">
            <RobolButton
              onClick={() =>
                handleNavigation(`../admin/employee/edit/${employeeID}`)
              }
              theme="green"
              size="m"
            >
              Bewerken
            </RobolButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmployeeCard;
