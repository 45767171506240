// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formik-label {
  margin-bottom: 5px; /* Adjust as needed for spacing between label and input */
}


/* YourCssFileName.css */
.formik-input {
  border: 1px solid #ccc;
  border-radius: 20px;
    padding: 10px 10px; /* Adjust padding as needed */
    font-size: 12px; /* Adjust font-size as needed */
    line-height: 1.5;
    width: 100%; /* Full width */
  }
  
  .formik-input:focus {
    border-color: #003366; /* Focus color */
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); /* Bootstrap's default focus shadow */
  }
  
  .form-group .text-danger {
    margin-top: .25rem;
    font-size: 0.7rem;
    position: absolute; /* Position error message correctly */
    bottom: -20px; /* Positioning below the input field */
    margin-left: 0.25rem;
  }
  
  /* Additional container styling */
  .form-group {
    position: relative; /* Needed for absolute positioning of the error message */
    margin-bottom: 15px; /* Space for error message */
  }
  
  .is-invalid {
    border-color: #dc3545; /* Bootstrap's default invalid border color */
  }
`, "",{"version":3,"sources":["webpack://./src/formik/components/css/FormikInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB,EAAE,yDAAyD;AAC/E;;;AAGA,wBAAwB;AACxB;EACE,sBAAsB;EACtB,mBAAmB;IACjB,kBAAkB,EAAE,6BAA6B;IACjD,eAAe,EAAE,+BAA+B;IAChD,gBAAgB;IAChB,WAAW,EAAE,eAAe;EAC9B;;EAEA;IACE,qBAAqB,EAAE,gBAAgB;IACvC,UAAU;IACV,4CAA4C,EAAE,qCAAqC;EACrF;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB,EAAE,qCAAqC;IACzD,aAAa,EAAE,sCAAsC;IACrD,oBAAoB;EACtB;;EAEA,iCAAiC;EACjC;IACE,kBAAkB,EAAE,yDAAyD;IAC7E,mBAAmB,EAAE,4BAA4B;EACnD;;EAEA;IACE,qBAAqB,EAAE,6CAA6C;EACtE","sourcesContent":[".form-group {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.formik-label {\n  margin-bottom: 5px; /* Adjust as needed for spacing between label and input */\n}\n\n\n/* YourCssFileName.css */\n.formik-input {\n  border: 1px solid #ccc;\n  border-radius: 20px;\n    padding: 10px 10px; /* Adjust padding as needed */\n    font-size: 12px; /* Adjust font-size as needed */\n    line-height: 1.5;\n    width: 100%; /* Full width */\n  }\n  \n  .formik-input:focus {\n    border-color: #003366; /* Focus color */\n    outline: 0;\n    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); /* Bootstrap's default focus shadow */\n  }\n  \n  .form-group .text-danger {\n    margin-top: .25rem;\n    font-size: 0.7rem;\n    position: absolute; /* Position error message correctly */\n    bottom: -20px; /* Positioning below the input field */\n    margin-left: 0.25rem;\n  }\n  \n  /* Additional container styling */\n  .form-group {\n    position: relative; /* Needed for absolute positioning of the error message */\n    margin-bottom: 15px; /* Space for error message */\n  }\n  \n  .is-invalid {\n    border-color: #dc3545; /* Bootstrap's default invalid border color */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
