import React, { useState } from "react";
import EditUserForm, { UserForm } from "../../formik/components/EditUserForm";
import * as Yup from "yup";
import "../../css/PrivateRoutes/AddEmployee.css";
import { roleSelectbox } from "../../helpers/jsonData";

const EditEmployee = () => {
  const [activeSection, setActiveSection] = useState("personalDetails");

  const toggleSwitches = [{ name: "available", label: "Beschikbaar" }];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Voornaam verplicht"),
    // lastName: Yup.string().required("Achternaam verplicht"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"), // Marking email as required and should be in a valid email format
    // phoneNumber: Yup.string().required("Phone Number is required"), // Marking phoneNumber as required
    // country: Yup.string().required("Country is required"), // Marking country as required
    // stateRegion: Yup.string(), // Optional field, not marked as required
    // city: Yup.string(), // Optional field, not marked as required
    // address: Yup.string().required("Address is required"), // Marking address as required
    // zipCode: Yup.string().required("Zip/Code is required"), // Marking zipCode as required
  });

  // Define input field configuration
  const fieldConfigurations = [
    {
      name: "role",
      type: "select",
      label: "Autorisatie",
      selectOptions: roleSelectbox,
    },
    {
      name: "function_title",
      type: "text",
      label: "Functie titel",
    },
    {
      name: "firstName",
      type: "text",
      label: "Voornaam",
      placeholder: "Voornaam",
    },
    {
      name: "lastName",
      type: "text",
      label: "Achternaam",
      placeholder: "Achternaam",
    },

    {
      name: "email",
      type: "email",
      label: "Email",
      placeholder: "Email",
    },
    {
      name: "phoneNumber",
      type: "text",
      label: "Telefoonnummer",
      placeholder: "Telefoonnummer",
    },
    { name: "city", type: "text", label: "Stad", placeholder: "Stad" },
    { name: "address", type: "text", label: "Adres", placeholder: "Adres" },
    {
      name: "zipCode",
      type: "text",
      label: "Postcode",
      placeholder: "Postcode",
    },
    {
      name: "holidays",
      type: "text",
      label: "Vakanties",
      placeholder: "Vakanties",
    },
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    role: "",
    profileImage: "",
    email: "",
    phoneNumber: "",
    stateRegion: "",
    city: "",
    address: "",
    zipCode: "",
    about: "",
    function_title: "",
    available: false,
    isActive: false,
    vacations: [{ fromDate: "", toDate: "" }],
    workingSchedule: {
      monday: { startTime: "", endTime: "" },
      tuesday: { startTime: "", endTime: "" },
      wednesday: { startTime: "", endTime: "" },
      thursday: { startTime: "", endTime: "" },
      friday: { startTime: "", endTime: "" },
      saturday: { startTime: "", endTime: "" },
      sunday: { startTime: "", endTime: "" },
    },
  };

  const handleSubmit = (values) => {
    // Handle form submission here
    console.log(values);
  };

  return (
    <EditUserForm
      fields={fieldConfigurations}
      toggleSwitches={toggleSwitches}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitButtonText="Wijzigingen opslaan"
      sideSteps={true}
    />
  );
};

export default EditEmployee;
