import React, { useEffect, useState } from "react";
import { GeneralLoader } from "../../components/GeneralLoader";
import { useAuth } from "../../context/AuthContext";
import { auth } from "../../firebase";
import {
  allCompanyUsers,
  getCompanyBots,
} from "../../services/api/UserServices";
import AdminDashboard from "./AdminDashboard";

const PreAdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [bots, setBots] = useState([]);
  const { currentUser } = useAuth();

  const userEmail = currentUser?.email;

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser || !userEmail) {
        setIsLoading(false);
        return;
      }

      try {
        const token = await auth.currentUser.getIdToken(true);
        const responseData = await allCompanyUsers(userEmail);

        const { data: usersData } = responseData;
        setCompanyUsers(usersData);

        const botsData = await getCompanyBots(userEmail, token);
        setBots(botsData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentUser, userEmail]);

  return (
    <>
      {isLoading ? (
        <GeneralLoader />
      ) : (
        <AdminDashboard companyUsers={companyUsers} bots={bots} />
      )}
    </>
  );
};

export default PreAdminDashboard;
