import React, { useRef } from "react";
import "../../css/PrivateRoutes/ProfileImageUpload.css";
import RobolButton from "../RobolButton";

export const ProfileImageUpload = ({ name, handleChange, profileImage }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="custom-form-group text-center">
      {profileImage ? (
        <div className="profile-image-container">
          <img
            src={
              profileImage.startsWith("data:image")
                ? profileImage
                : `data:image/jpeg;base64,${profileImage}`
            }
            alt="Profile"
            className="profile-image-circle"
          />
        </div>
      ) : (
        <div className="custom-file-drop-area">
          <label className="dropPicture" htmlFor="filephotos">
            Profiele foto uploaden
          </label>
        </div>
      )}
      <input
        type="file"
        name={name}
        id="filephotos"
        ref={fileInputRef}
        className="d-none"
        onChange={handleChange}
      />
      <RobolButton onClick={handleButtonClick} theme="dark" size="m">
        Profielfoto aanpassen
      </RobolButton>
    </div>
  );
};
