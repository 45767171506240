import React from "react";
import "../css/ConfirmationModal.css"; // Ensure you have the corresponding CSS file
import RobolButton from "./RobolButton";

const ConfirmationModal = ({ isOpen, onConfirm, onCancel, message }) => {
  if (!isOpen) return null;

  return (
    <div className="chat_modal">
      <div className="chat_modalcontent">
        <div className="container">
          <div className="row">
            <div className="col">{message}</div>
          </div>
          <div className="mt-3 row align-items-center">
            <div className="col">
              <RobolButton size="l" theme="dark" onClick={onConfirm}>
                Ja
              </RobolButton>
            </div>
            <div className="col">
              <RobolButton size="l" theme="green" onClick={onCancel}>
                Nee
              </RobolButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
