export const GptModels = {
  GPT_35_TURBO: 1,
  GPT_35_TURBO_16k: 2,
  GPT_35_TURBO_1106: 3,
  GPT_40: 4,
  GPT_40_32k: 5,
};

export const TooltipContent = {
  PERSONALITY: "In het kort over het persoonlijkheid van je chatbot.",
  COMPANY: "In asdas",
  RESTRICTIONS: "In asdas",
  LIVE_APIS: "In asdas",
  MODEL_POWER: "In asdas",
  COLORS: "In asdas",
  INTERACTIVE_TEST: "In asdas",
  WORKSCHEDULE: "Workascheudasld",
  HOLIDAYS: "HOlidaysdasd",
  ASK_AI:
    "Klik om de bericht te kopieren en direct te vragen aan je AI Assistent",
};

export const ForwardingStatus = {
  NOT_FORWARDED: 0,
  IS_FORWARDED: 1,
  FORWARD_REQUESTED: 2,
  REJECTED: 3,
};

export const MessagesStatus = {
  IS_NOT_READ: 0,
  IS_READ: 1,
};

export const ConversationClosed = {
  IS_OPEN: 0,
  IS_CLOSED: 1,
};

export const ToastNotifications = {
  AVAILBILITY_CHANGE: "Beschikbaarheid aangepast succesvol!",
  CHANGES_SAVED: "Wijzigingen automatisch opgeslagen!",
  DELETED: "Verwijderd!",
  UPLOADED: "Document succesvol geupload. Wij werken uw document, u kunt dit scherm verlaten.",
  SCRAPE_START: "Scrapen gestart! Dit proces duurt maximaal 1 minuut.",
  SCRAPE_ERROR: "Scrapen helaas niet gelukt. Probeer later nogmaals",
  SCRAPE_SUCCEDED: "Scrapen succesvol afgerond!",
  BOT_ACTIVATED: "Jou AI-bot is succesvol geactiveerd!",
  ERROR: "Er is iets mis gegaan, Probeer het later opnieuw.",
};

export const UserRoles = {
  ADMIN: "ADMIN",
  USER: "USER",
};

//TODO: Translation
