import React, { useEffect, useState } from "react";
import CardWrapper from "./CardWrapper";
import { DocumentUpload } from "./DocumentUpload";
import DocumentTable from "./DocumentTable";

const DocumentScraperCard = ({
  currentManualStep,
  initialValues,
  chatbotID,
  onDocumentUpload,
  onDocumentDelete,
  onDocumentsUpdate,
}) => {
  const [documentsList, setDocumentsList] = useState([]);

  useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      const formattedDocuments = initialValues.map((doc) => ({
        id: doc.id,
        name: doc.name,
        format: doc.type,
        timestamp: new Date(doc.date_scraped).toLocaleString(),
        user: doc.user || "Unknown",
        uploaded: true,
      }));
      setDocumentsList(formattedDocuments);
    } else {
      setDocumentsList([]); // Ensure the documents list is cleared if initialValues is empty
    }
  }, [initialValues]);

  const handleDelete = async (documentId) => {
    await onDocumentDelete(documentId);
    // onDocumentsUpdate(); // Trigger a refresh from the parent component
  };

  return (
    <div className="row">
      <div className="block-margin"></div>
      <div className="col pb-5">
        <CardWrapper className="mb-2" minHeight="50vh">
          <div className="row">
            <div className="col d-flex justify-content-between align-items-center">
              <h5>{currentManualStep}</h5>
            </div>
          </div>
          <hr className="black" />
          <div className="col pb-5">
            <div className="user-card mb-3">
              <DocumentUpload
                name="document"
                onDocumentUpload={onDocumentUpload}
                chatbotID={chatbotID}
              />
            </div>
          </div>
          <DocumentTable
            chatbotID={chatbotID}
            documents={documentsList}
            onDelete={handleDelete}
          />
        </CardWrapper>
      </div>
    </div>
  );
};

export default DocumentScraperCard;
