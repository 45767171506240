import PropTypes from "prop-types";
import React, { useState } from "react";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import { CheckIconCircle } from "../assets/svgs/SvgIcons";
import "../css/PrivateRoutes/DynamicTable.css";

const DynamicTable = ({ columns, data, onRowClick }) => {
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const toggleSort = (columnName) => {
    if (sortedColumn === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(columnName);
      setSortOrder("asc");
    }
  };

  const renderSortableHeader = (column) => {
    return (
      <th
        className={`sortable ${column.className || ""} ${
          sortedColumn === column.accessor ? "active" : ""
        }`}
        onClick={() => toggleSort(column.accessor)}
      >
        {column.Header}
        {sortedColumn === column.accessor &&
          (sortOrder === "asc" ? <CaretUpFill /> : <CaretDownFill />)}
      </th>
    );
  };

  const renderRow = (row, rowIndex) => {
    return (
      <tr key={rowIndex} onClick={() => onRowClick(row)}>
        {columns.map((column, columnIndex) => (
          <td key={columnIndex} className={column.className}>
            {column.checkIcon ? (
              <div className="align-center">
                <CheckIconCircle width="30" height="30" color="#26C87C" />
                <span className="ml-2">{row[column.accessor]}</span>
              </div>
            ) : column.Cell ? (
              column.Cell({ original: row })
            ) : (
              row[column.accessor]
            )}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div className="table-responsive">
      <table className="table mb-3 purple-text-table">
        <thead>
          <tr>
            {columns.map((column) =>
              column.sortable ? (
                renderSortableHeader(column)
              ) : (
                <th key={column.accessor} className={column.className}>
                  {column.Header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>{data.map(renderRow)}</tbody>
      </table>
    </div>
  );
};

DynamicTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  onEditClick: PropTypes.func,
  filters: PropTypes.array,
  onFilterChange: PropTypes.func,
};

export default DynamicTable;
