// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompt-card-wrapper {
    background: var(--rbl-green-l);
    border-radius: 8px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    padding: 20px 0;
    /* Apply padding to top and bottom only */
    width: auto;
    height: auto;
    overflow: hidden;
    position: relative;
    /* For absolute positioning of header elements */
}

.prompt-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    /* Apply horizontal margin to align with the padding of the wrapper */
}

.prompt-card-title {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
}

.prompt-card-actions {
    display: flex;
}

.prompt-card-action-btn {
    background: none;
    border: none;
    cursor: pointer;
}

.prompt-card-divider {
    border: 0;
    height: 1px;
    background: #000000;
    margin: 1.3rem 0;
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/PromptCardWrapper.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,6CAA6C;IAC7C,eAAe;IACf,yCAAyC;IACzC,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,gDAAgD;AACpD;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,qEAAqE;AACzE;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,WAAW;IACX,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".prompt-card-wrapper {\n    background: var(--rbl-green-l);\n    border-radius: 8px;\n    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);\n    padding: 20px 0;\n    /* Apply padding to top and bottom only */\n    width: auto;\n    height: auto;\n    overflow: hidden;\n    position: relative;\n    /* For absolute positioning of header elements */\n}\n\n.prompt-card-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 0 20px;\n    /* Apply horizontal margin to align with the padding of the wrapper */\n}\n\n.prompt-card-title {\n    font-size: 18px;\n    font-weight: bold;\n    color: #000000;\n}\n\n.prompt-card-actions {\n    display: flex;\n}\n\n.prompt-card-action-btn {\n    background: none;\n    border: none;\n    cursor: pointer;\n}\n\n.prompt-card-divider {\n    border: 0;\n    height: 1px;\n    background: #000000;\n    margin: 1.3rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
