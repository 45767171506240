import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import i18n from "./components/Translations/i18n";
import { AuthProvider } from "./context/AuthContext";
import reportWebVitals from "./reportWebVitals";
import { CustomCloseButton } from "./formik/components/Toast";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <BrowserRouter>
          <ToastContainer
            position="top-center"
            autoClose={1500}
            style={{
              width: "100%",
              maxWidth: "400px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            closeButton={<CustomCloseButton />}
          />
          <App />
        </BrowserRouter>
      </AuthProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
