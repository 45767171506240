import React from "react";
import { useNavigate } from "react-router-dom";

const ClickThroughText = ({
  title = "",
  firstText = "",
  clickText = "",
  navigateTo = "/",
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(navigateTo);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <h5>{title}</h5>
          <small>
            {firstText}{" "}
            <span className="click-trough-text" onClick={handleNavigation}>
              {clickText}
            </span>
          </small>
        </div>
      </div>
    </div>
  );
};

export default ClickThroughText;
