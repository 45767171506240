import {
  deleteFromAPI,
  fetchFromAPI,
  patchToAPI,
  postToAPI,
  uploadToAPI,
} from "./ApiGeneral";

export const getCompanyContent = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/content/api/companyContent?chatbotID=${chatbotID}`,
    token
  );
};

export const getCharacterContent = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/content/api/character?chatbotID=${chatbotID}`,
    token
  );
};

export const getPromptCategoryContent = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/content/api/promptCategory?chatbotID=${chatbotID}`,
    token
  );
};

export const getPrompts = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/content/api/prompt?chatbotID=${chatbotID}`,
    token
  );
};

export const getCategoryAndPrompt = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/content/api/categoryAndPrompt?chatbotID=${chatbotID}`,
    token
  );
};

export const getRestrictions = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/content/api/restrictions?chatbotID=${chatbotID}`,
    token
  );
};

export const getScrapedDocuments = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/scraper/api/scrape_document?chatbotID=${chatbotID}`,
    token
  );
};
export const getScrapedWebsites = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/scraper/api/scrape_website?chatbotID=${chatbotID}`,
    token
  );
};

export const getLayoutConfiguration = async (chatbotID, token) => {
  return await fetchFromAPI(
    `/api/v1/layout/api/configLayout?chatbotID=${chatbotID}`,
    token
  );
};

export const postCompanyContent = async (chatbotID, token, companyContent) => {
  const body = { content: JSON.stringify(companyContent) };
  return await postToAPI(
    `/api/v1/content/api/companyContent?chatbotID=${chatbotID}`,
    token,
    body
  );
};

export const postPersonalityContent = async (chatbotID, token, character) => {
  const body = { character: character };
  return await postToAPI(
    `/api/v1/content/api/character?chatbotID=${chatbotID}`,
    token,
    body
  );
};

export const postRestriction = async (chatbotID, token, text) => {
  const body = { text };
  return await postToAPI(
    `/api/v1/content/api/restrictions?chatbotID=${chatbotID}`,
    token,
    body
  );
};

export const deleteRestrictionApi = async (chatbotID, token, restrictionId) => {
  return await deleteFromAPI(
    `/api/v1/content/api/restrictions?chatbotID=${chatbotID}&id=${restrictionId}`,
    token
  );
};

export const postPromptCategory = async (chatbotID, token, name) => {
  const body = { name };
  return await postToAPI(
    `/api/v1/content/api/promptCategory?chatbotID=${chatbotID}&name=${name}`,
    token,
    body
  );
};

export const postPrompt = async (chatbotID, token, { text, categoryID }) => {
  const body = { text };
  return await postToAPI(
    `/api/v1/content/api/prompt?chatbotID=${chatbotID}&categoryID=${categoryID}`,
    token,
    body
  );
};

export const deletePromptCategoryApi = async (chatbotID, token, categoryID) => {
  return await deleteFromAPI(
    `/api/v1/content/api/promptCategory?chatbotID=${chatbotID}&id=${categoryID}`,
    token
  );
};

export const deletePromptApi = async (chatbotID, token, promptID) => {
  return await deleteFromAPI(
    `/api/v1/content/api/prompt?chatbotID=${chatbotID}&promptID=${promptID}`,
    token
  );
};

export const uploadScrapedDocument = async (chatbotID, token, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return await uploadToAPI(
    `/api/v1/scraper/api/scrape_document?chatbotID=${chatbotID}`,
    token,
    formData
  );
};

export const deleteScrapedDocument = async (chatbotID, token, documentId) => {
  return await deleteFromAPI(
    `/api/v1/scraper/api/file?chatbotID=${chatbotID}&id=${documentId}`,
    token
  );
};

export const postScrapedWebsite = async (chatbotID, token, url) => {
  const body = { url };
  return await postToAPI(
    `/api/v1/scraper/api/scrape_website?chatbotID=${chatbotID}&url=${url}`,
    token,
    body
  );
};

export const deleteScrapedWebsite = async (chatbotID, token, id) => {
  return await deleteFromAPI(
    `/api/v1/scraper/api/file?chatbotID=${chatbotID}&id=${id}`,
    token
  );
};

export const patchLayoutConfiguration = async (
  chatbotID,
  token,
  layoutConfig
) => {
  return await patchToAPI(
    `/api/v1/layout/api/configLayout?chatbotID=${chatbotID}`,
    token,
    layoutConfig
  );
};

export const postLayoutConfiguration = async (chatbotID, token) => {
  return await patchToAPI(
    `/api/v1/layout/api/configLayout?chatbotID=${chatbotID}`,
    token
  );
};

export const createChatbot = async (employeeName, token) => {
  const body = { employeeName };
  return await postToAPI(`/api/createChat`, token, body);
};
