// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompts-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* This will make sure it takes full height of the parent */
}


@media (max-width: 768px) {
  .block-margin{
    display: none;
  }

}

.block-margin {
  width: 26%;
}

.category-item {
  padding: 1rem;
}

.prompt-item {
  border-radius: 8px;
  padding: 1rem;
  background: white;
  box-shadow: 1px 1px 1px 1px rgba(204, 198, 198, 0);
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/PromptsCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,2DAA2D;AAC7D;;;AAGA;EACE;IACE,aAAa;EACf;;AAEF;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,kDAAkD;AACpD","sourcesContent":[".prompts-card {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  /* This will make sure it takes full height of the parent */\n}\n\n\n@media (max-width: 768px) {\n  .block-margin{\n    display: none;\n  }\n\n}\n\n.block-margin {\n  width: 26%;\n}\n\n.category-item {\n  padding: 1rem;\n}\n\n.prompt-item {\n  border-radius: 8px;\n  padding: 1rem;\n  background: white;\n  box-shadow: 1px 1px 1px 1px rgba(204, 198, 198, 0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
