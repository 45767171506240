// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
    position: relative;
}

.search-input {
    width: 50%;
    padding-left: 40px;
    /* Adjust padding to make room for the icon */
    border-radius: 5px !important;
    /* Increased border-radius for rounded corners */
    border: 1px solid #dddddd73;
    /* Lighter border color */
    font-size: 1rem;
    /* Adjust font size as needed */
    height: 40px;
    /* Adjust height as needed */
    outline: none;
    /* Removes the blue outline on focus */
    box-shadow: none;
    /* Removes the default bootstrap shadow if any */
    transition: border-color 0.2s;
    /* Smooth transition for border color */
}

.search-input:focus {
    border-color: #aaa;
    /* Slightly darker border on focus for visibility */
}

.search-bar svg {
    position: absolute;
    left: 12px;
    /* Adjust as needed */
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: #1E3050;
    /* Icon color */
}

/* If you're using a framework like Bootstrap, ensure your styles override the framework's styles */
.form-control:focus {
    box-shadow: none;
    /* Removes the default bootstrap shadow on focus */
}`, "",{"version":3,"sources":["webpack://./src/css/SearchBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,6CAA6C;IAC7C,6BAA6B;IAC7B,gDAAgD;IAChD,2BAA2B;IAC3B,yBAAyB;IACzB,eAAe;IACf,+BAA+B;IAC/B,YAAY;IACZ,4BAA4B;IAC5B,aAAa;IACb,sCAAsC;IACtC,gBAAgB;IAChB,gDAAgD;IAChD,6BAA6B;IAC7B,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,mDAAmD;AACvD;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,qBAAqB;IACrB,QAAQ;IACR,2BAA2B;IAC3B,WAAW;IACX,cAAc;IACd,eAAe;AACnB;;AAEA,mGAAmG;AACnG;IACI,gBAAgB;IAChB,kDAAkD;AACtD","sourcesContent":[".search-bar {\n    position: relative;\n}\n\n.search-input {\n    width: 50%;\n    padding-left: 40px;\n    /* Adjust padding to make room for the icon */\n    border-radius: 5px !important;\n    /* Increased border-radius for rounded corners */\n    border: 1px solid #dddddd73;\n    /* Lighter border color */\n    font-size: 1rem;\n    /* Adjust font size as needed */\n    height: 40px;\n    /* Adjust height as needed */\n    outline: none;\n    /* Removes the blue outline on focus */\n    box-shadow: none;\n    /* Removes the default bootstrap shadow if any */\n    transition: border-color 0.2s;\n    /* Smooth transition for border color */\n}\n\n.search-input:focus {\n    border-color: #aaa;\n    /* Slightly darker border on focus for visibility */\n}\n\n.search-bar svg {\n    position: absolute;\n    left: 12px;\n    /* Adjust as needed */\n    top: 50%;\n    transform: translateY(-50%);\n    z-index: 10;\n    color: #1E3050;\n    /* Icon color */\n}\n\n/* If you're using a framework like Bootstrap, ensure your styles override the framework's styles */\n.form-control:focus {\n    box-shadow: none;\n    /* Removes the default bootstrap shadow on focus */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
