import React from "react";
import "../../css/PrivateRoutes/StepCardsWrapper.css";

const StepCardsWrapper = ({ children, onClick, active }) => {
  return (
    <div
      className={`mt-3 steps-wrapper ${active ? "active" : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default StepCardsWrapper;
