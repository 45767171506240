import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import "../../css/PrivateRoutes/UserOverview.css";
import { auth } from "../../firebase";
import {
  getAvailability,
  toggleUserActiveStatus,
} from "../../services/api/UserServices";
import { UserOverviewHeader } from "../userOverview/UserOverviewHeader";
import InboxGeneral from "./InboxGeneral";

const MessagesHub = ({ role }) => {
  const [availability, setAvailability] = useState(false);
  const { currentUser } = useAuth();
  const [employeeName, setEmployeeName] = useState([]);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const data = await getAvailability(currentUser?.email, token);
        setAvailability(data.is_active);
        setEmployeeName(data);
      } catch (error) {
        console.error("Error fetching availability:", error.message);
      }
    };

    fetchAvailability();
  }, []);

  const initialValues = {
    available: availability,
  };

  const handleSubmit = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      await toggleUserActiveStatus(currentUser?.email, token);
      const updatedAvailability = await getAvailability(
        currentUser?.email,
        token
      );
      setAvailability(updatedAvailability.is_active);
      console.log("User active status toggled successfully");
    } catch (error) {
      console.error("Error toggling user active status:", error.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      handleSubmit(values);
    },
  });

  

  return (
    <FormikProvider value={formik}>
      <UserOverviewHeader
        first_name={employeeName?.first_name}
        last_name={employeeName?.last_name}
        toggleSubmit={formik.submitForm}
        picture={employeeName?.picture}
      />
      <InboxGeneral role={role} />
    </FormikProvider>
  );
};

export default MessagesHub;
