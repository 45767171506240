import React, { useState } from "react";
import "../css/Pagination.css";

const Pagination = ({
  totalItems,
  itemsPerPageOptions,
  defaultItemsPerPage,
  onChangePage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const goToFirstPage = () => {
    setCurrentPage(1);
    onChangePage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    onChangePage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    onChangePage(currentPage + 1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
    onChangePage(totalPages);
  };

  return (
    <nav className="pagination-container">
      <div className="pagination-controls"></div>
      <div className="pagination-controls">
        <span className="pagination-info">
          {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
            currentPage * itemsPerPage,
            totalItems
          )} of ${totalItems}`}
        </span>
        <button
          onClick={goToFirstPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          &laquo;
        </button>
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          &lsaquo;
        </button>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          &rsaquo;
        </button>
        <button
          onClick={goToLastPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          &raquo;
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
