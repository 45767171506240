import * as React from "react";

export const CustomLogo = ({ width }) => {
  const aspectRatio = 807.309 / 807.309;
  const height = width * aspectRatio;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 807.309 807.309"
    >
      <g
        id="Group_243"
        data-name="Group 243"
        transform="translate(1516.708 3097.691)"
      >
        <g id="Group_242" data-name="Group 242">
          <g id="Group_135" data-name="Group 135">
            <g id="SvgjsG2316" transform="translate(-1516.708 -3097.691)">
              <rect
                id="Rectangle_88"
                data-name="Rectangle 88"
                width="807.309"
                height="807.309"
                rx="89.701"
                fill="#fff"
              />
            </g>
            <g id="SvgjsG2317" transform="translate(-1419.511 -3008.385)">
              <path
                id="Path_130"
                data-name="Path 130"
                d="M491.252,336.957V125.926L379.163,62.271h-111.4L435.9,157.754V368.785Z"
                fill="#036"
              />
              <path
                id="Path_131"
                data-name="Path 131"
                d="M200.652,456.657,379.163,560.442h-.692l2.768,2.076L496.1,496.1l55.352-96.175-168.825,98.25L200.652,393Z"
                fill="#036"
              />
              <path
                id="Path_132"
                data-name="Path 132"
                d="M423.445,394.385h.692L324.5,451.121l54.66,31.828L560.442,377.088V243.55L505.09,147.376V344.568Z"
                fill="#036"
              />
              <path
                id="Path_133"
                data-name="Path 133"
                d="M422.061,165.365,240.783,60.2,125.926,126.618,70.574,223.485l171.592-98.942,83.028,48.433h-1.384l98.25,56.736Z"
                fill="#036"
              />
              <path
                id="Path_134"
                data-name="Path 134"
                d="M243.55,140.456,62.271,246.318V379.163l55.352,96.175V278.145l81.645-48.433h0L298.9,172.284Z"
                fill="#036"
              />
              <path
                id="Path_135"
                data-name="Path 135"
                d="M131.462,285.756V496.787L243.55,560.442H355.639L186.814,464.959V253.929Z"
                fill="#036"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
