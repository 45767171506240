import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SidebarSubitems } from "../assets/EnumSidebarConfigurator";
import ActiveStepContext from "../context/EditBotSteps/ActiveStepContext";
import "../css/PrivateRoutes/EditBot.css";
import { auth } from "../firebase";
import {
  deletePromptApi,
  deletePromptCategoryApi,
  deleteRestrictionApi,
  deleteScrapedDocument,
  deleteScrapedWebsite,
  getCategoryAndPrompt,
  getCharacterContent,
  getCompanyContent,
  getLayoutConfiguration,
  getPromptCategoryContent,
  getPrompts,
  getRestrictions,
  getScrapedDocuments,
  getScrapedWebsites,
  patchLayoutConfiguration,
  postCompanyContent,
  postPersonalityContent,
  postPrompt,
  postPromptCategory,
  postRestriction,
  postScrapedWebsite,
  uploadScrapedDocument,
} from "../services/api/FineTunerService";
import { GeneralLoader } from "./GeneralLoader";
import ActivationCard from "./PrivateRoutes/ActivationCard";
import DocumentScraperCard from "./PrivateRoutes/DocumentScraperCard";
import IntelligenceCard from "./PrivateRoutes/IntelligenceCard";
import InteractiveTesterCard from "./PrivateRoutes/InteractiveTesterCard";
import LayoutCard from "./PrivateRoutes/LayoutCard";
import LiveApiCard from "./PrivateRoutes/LiveApiCard";
import ModelPowerCard from "./PrivateRoutes/ModelPowerCard";
import PromptsCard from "./PrivateRoutes/PromptsCard";
import RestrictionsCard from "./PrivateRoutes/RestrictionsCard";
import WebScraperCard from "./PrivateRoutes/WebScraperCard";
import { turnOnTheBot } from "../services/api/UserServices";

const EditBot = () => {
  const { chatbotID } = useParams();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [refreshToken, setRefreshToken] = useState(false);

  const [initialValues, setInitialValues] = useState({
    design: {
      color_primary: "#009933",
      color_secondary: "#009933",
      color_tertiary: "#003366",
      color_button: "#009933",
      avatarChatBot: "",
      avatarChatBox: "",
      isTouched: false,
      isSaved: false,
    },
    restrictions: {
      list: [],
      isTouched: false,
      isSaved: false,
    },
    interactiveTester: {
      corrections: "",
    },
    gptModel: {
      model: 2,
    },
    personality: {
      personality: "",
      isTouched: false,
      isSaved: false,
    },
    company: {
      company: "",
      isTouched: false,
      isSaved: false,
    },
    categories: {
      categories: [],
      isTouched: false,
      isSaved: false,
    },
    documentScrapers: {
      scrapedInfo: [],
      isTouched: false,
      isSaved: false,
    },
    activeApi: {
      apiName: "",
      apiUrl: "",
      apiCategory: "",
      apiKeywords: "",
      isTouched: false,
      isSaved: false,
    },
    webScrapers: {
      scrapedUrls: [],
      isTouched: false,
      isSaved: false,
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { activeStep, setChangingStep, setActiveStep } = useContext(ActiveStepContext);

  useEffect(() => {
    const saveChanges = async () => {
      const token = await auth.currentUser.getIdToken(true);
      if (initialValues?.personality.isSaved && initialValues?.personality.isTouched) {
        try {
          await postPersonalityContent(chatbotID, token, initialValues.personality.personality);
          setInitialValues((prevValues) => ({
            ...prevValues,
            personality: {
              ...prevValues.personality,
              isSaved: true,
              isTouched: false,
            },
          }));
        } catch (error) {
          console.error("Error saving personality content:", error);
        }
      }
      if (initialValues?.company.isSaved && initialValues?.company.isTouched) {
        try {
          await postCompanyContent(chatbotID, token, initialValues.company.company);
          setInitialValues((prevValues) => ({
            ...prevValues,
            company: {
              ...prevValues.company,
              isSaved: true,
              isTouched: false,
            },
          }));
        } catch (error) {
          console.error("Error saving company content:", error);
        }
      }
    };

    saveChanges();
  }, [activeStep]);

  useEffect(() => {
    const fetchData = async () => {
      const token = await auth.currentUser.getIdToken(true);

      try {
        const [
          companyContentData,
          characterContentData,
          promptCategoryContentData,
          promptsData,
          categoryAndPromptData,
          restrictionsData,
          scrapedDocumentsData,
          scrapedWebsiteData,
          layoutConfigurationData,
        ] = await Promise.all([
          getCompanyContent(chatbotID, token),
          getCharacterContent(chatbotID, token),
          getPromptCategoryContent(chatbotID, token),
          getPrompts(chatbotID, token),
          getCategoryAndPrompt(chatbotID, token),
          getRestrictions(chatbotID, token),
          getScrapedDocuments(chatbotID, token),
          getScrapedWebsites(chatbotID, token),
          getLayoutConfiguration(chatbotID, token),
        ]);
        setInitialValues((prevValues) => ({
          ...prevValues,
          company: {
            ...prevValues.company,
            company: companyContentData.companyContent || "",
          },
          personality: {
            ...prevValues.personality,
            personality: characterContentData.character || "",
          },
          categories: {
            ...prevValues.categories,
            categories: categoryAndPromptData,
          },
          restrictions: {
            list: restrictionsData.map((r) => ({
              ...r,
              isTouched: false,
              isSaved: true,
            })),
            isTouched: false,
            isSaved: true,
          },
          documentScrapers: {
            scrapedInfo: scrapedDocumentsData,
            isTouched: false,
            isSaved: true,
          },
          webScrapers: {
            scrapedUrls: scrapedWebsiteData,
            isTouched: false,
            isSaved: true,
          },
          layoutConfiguration: layoutConfigurationData,
        }));
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [chatbotID, refreshToken]);

  useEffect(() => {
    setChangingStep(false);
  }, [activeStep]);

  const handleSubmit = async (values) => {
    // const token = await auth.currentUser.getIdToken(true);
    // try {
    //   const response = await turnOnTheBot(chatbotID, token);
    //   console.log("Bot availability toggled successfully:", response);
    // } catch (error) {
    //   console.error("Failed to toggle bot availability:", error.message);
    // }
  };

  const saveChanges = (values) => {
    // console.log("Saving changes:", values);
    // Tutaj będzie POST request do backendu w przyszłości
  };

  const handleStepChange = (newStep) => {
    checkForUnsavedChanges(initialValues);
    setActiveStep(newStep);
  };

  const handleSubmitRestrictionsValues = (values) => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      restrictions: values.restrictions,
    }));
  };

  const updateActiveApiValues = (newValues) => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      activeApi: { ...prevValues.activeApi, ...newValues },
    }));
  };

  const fetchRestrictions = async () => {
    console.log("deleting?");
    const token = await auth.currentUser.getIdToken(true);
    try {
      const restrictionsData = await getRestrictions(chatbotID, token);
      console.log("Fetched restrictions: ", restrictionsData);
      setInitialValues((prevValues) => ({
        ...prevValues,
        restrictions: {
          list: restrictionsData.map((r) => ({
            ...r,
            isTouched: false,
            isSaved: true,
          })),
          isTouched: false,
          isSaved: true,
        },
      }));
    } catch (error) {
      console.error("Error fetching restrictions: ", error);
    }
  };

  const saveRestrictions = async (restrictionMessage) => {
    const token = await auth.currentUser.getIdToken(true);
    console.log("saveRestrictions called");
    try {
      await postRestriction(chatbotID, token, restrictionMessage);
      await fetchRestrictions();
    } catch (error) {
      console.error("Error saving restrictions content:", error);
    }
  };

  const saveCategory = async (categoryName) => {
    const token = await auth.currentUser.getIdToken(true);
    try {
      await postPromptCategory(chatbotID, token, categoryName);
      const updatedCategories = await getCategoryAndPrompt(chatbotID, token);
      setInitialValues((prevValues) => ({
        ...prevValues,
        categories: {
          categories: updatedCategories,
          isTouched: false,
          isSaved: true,
        },
      }));
    } catch (error) {
      console.error("Error saving category:", error);
    }
  };

  const savePrompt = async (categoryId, promptText) => {
    const token = await auth.currentUser.getIdToken(true);
    try {
      await postPrompt(chatbotID, token, {
        text: promptText,
        categoryID: categoryId,
      });
      const updatedCategories = await getCategoryAndPrompt(chatbotID, token);
      setInitialValues((prevValues) => ({
        ...prevValues,
        categories: {
          categories: updatedCategories,
          isTouched: false,
          isSaved: true,
        },
      }));
    } catch (error) {
      console.error("Error saving prompt:", error);
    }
  };

  const deleteCategory = async (categoryId) => {
    const token = await auth.currentUser.getIdToken(true);
    console.log("deleteCategory called", categoryId);
    try {
      await deletePromptCategoryApi(chatbotID, token, categoryId);
      const updatedCategories = await getCategoryAndPrompt(chatbotID, token);
      setInitialValues((prevValues) => ({
        ...prevValues,
        categories: {
          categories: updatedCategories,
          isTouched: false,
          isSaved: true,
        },
      }));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const deletePrompt = async (promptId) => {
    const token = await auth.currentUser.getIdToken(true);
    console.log("deletePrompt called", promptId);
    try {
      await deletePromptApi(chatbotID, token, promptId);
      const updatedCategories = await getCategoryAndPrompt(chatbotID, token);
      setInitialValues((prevValues) => ({
        ...prevValues,
        categories: {
          categories: updatedCategories,
          isTouched: false,
          isSaved: true,
        },
      }));
    } catch (error) {
      console.error("Error deleting prompt:", error);
    }
  };

  const deleteRestriction = async (restrictionId) => {
    const token = await auth.currentUser.getIdToken(true);
    console.log("deleteRestriction called", restrictionId);
    try {
      await deleteRestrictionApi(chatbotID, token, restrictionId);
      console.log("Fetching restrictions after deletion");
      await fetchRestrictions();
    } catch (error) {
      console.error("Error deleting restriction:", error);
    }
  };

  const editRestriction = (restrictionId, updatedMessage) => {
    setInitialValues((prevValues) => {
      const updatedRestrictions = prevValues.restrictions.list.map((restriction) => {
        if (restriction.id === restrictionId) {
          return {
            ...restriction,
            text: updatedMessage,
            isTouched: true,
            isSaved: false,
          };
        }
        return restriction;
      });
      return {
        ...prevValues,
        restrictions: {
          ...prevValues.restrictions,
          list: updatedRestrictions,
          isTouched: true,
          isSaved: false,
        },
      };
    });
  };

  const checkForUnsavedChanges = (values) => {
    const unsavedChanges = {};

    for (const key in values) {
      if (values[key]?.isTouched && !values[key]?.isSaved) {
        unsavedChanges[key] = {
          ...values[key],
          isSaved: true,
        };
      }
    }

    if (Object.keys(unsavedChanges).length > 0) {
      console.log("Saving changes:", unsavedChanges);
      saveChanges(unsavedChanges);

      // Update initialValues with unsaved changes
      setInitialValues((prevValues) => ({
        ...prevValues,
        ...unsavedChanges,
      }));

      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
    }
  };

  const handleDocumentUpload = async (file) => {
    const token = await auth.currentUser.getIdToken(true);
    await uploadScrapedDocument(chatbotID, token, file);
    const scrapedDocuments = await getScrapedDocuments(chatbotID, token);
    setInitialValues((prev) => ({
      ...prev,
      documentScrapers: { scrapedInfo: scrapedDocuments },
    }));
  };

  const handleDocumentDelete = async (documentId) => {
    const token = await auth.currentUser.getIdToken(true);

    // Optimistically update the state by removing the deleted document
    setInitialValues((prev) => {
      const updatedScrapedInfo = prev.documentScrapers.scrapedInfo.filter((doc) => doc.id !== documentId);
      return {
        ...prev,
        documentScrapers: { scrapedInfo: updatedScrapedInfo },
      };
    });

    try {
      // Perform the delete operation
      await deleteScrapedDocument(chatbotID, token, documentId);

      // Introduce a delay before refetching the documents
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const scrapedDocuments = await getScrapedDocuments(chatbotID, token);

      if (scrapedDocuments.length > 0) {
        setInitialValues(() => ({
          documentScrapers: { scrapedInfo: scrapedDocuments },
        }));
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleWebsiteScrape = async (url) => {
    const token = await auth.currentUser.getIdToken(true);
    try {
      await postScrapedWebsite(chatbotID, token, url);
      const scrapedWebsitesData = await getScrapedWebsites(chatbotID, token);
      setInitialValues((prevValues) => ({
        ...prevValues,
        webScrapers: {
          scrapedUrls: scrapedWebsitesData,
          isTouched: false,
          isSaved: true,
        },
      }));
    } catch (error) {
      console.error("Error scraping website:", error);
    }
  };

  const handleWebsiteDelete = async (websiteId) => {
    const token = await auth.currentUser.getIdToken(true);
    try {
      await deleteScrapedWebsite(chatbotID, token, websiteId);
      const scrapedWebsitesData = await getScrapedWebsites(chatbotID, token);
      if (scrapedWebsitesData.length > 0) {
        setInitialValues((prevValues) => ({
          ...prevValues,
          webScrapers: {
            scrapedUrls: scrapedWebsitesData,
            isTouched: false,
            isSaved: true,
          },
        }));
      }
    } catch (error) {
      console.error("Error deleting website:", error);
    }
  };

  const saveLayoutConfiguration = async (layoutConfig) => {
    const token = await auth.currentUser.getIdToken(true);
    try {
      await patchLayoutConfiguration(chatbotID, token, layoutConfig);
      const updatedLayoutConfiguration = await getLayoutConfiguration(chatbotID, token);
      setInitialValues((prevValues) => ({
        ...prevValues,
        layoutConfiguration: updatedLayoutConfiguration,
      }));
    } catch (error) {
      console.error("Error saving layout configuration:", error);
    }
  };

  const renderFormSection = (setFieldValue, values, dirty, saveCategory) => {
    switch (activeStep) {
      case SidebarSubitems.PERSONALITY:
        return (
          <IntelligenceCard
            currentManualStep={activeStep}
            placeholder='In het kort over het persoonlijkheid van je chatbot..'
            formikField='personality'
          />
        );
      case SidebarSubitems.COMPANY:
        return (
          <IntelligenceCard
            currentManualStep={activeStep}
            placeholder='In het kort over je bedrijf..'
            formikField='company'
          />
        );
      case SidebarSubitems.RESTRICTIONS:
        return (
          <RestrictionsCard
            currentManualStep={SidebarSubitems.RESTRICTIONS}
            initialValues={values.restrictions}
            saveRestrictions={saveRestrictions}
            deleteRestriction={deleteRestriction}
            editRestriction={editRestriction}
          />
        );
      case SidebarSubitems.PROMPTS:
        return (
          <PromptsCard
            setCategoryValues={setInitialValues}
            deletePrompt={deletePrompt}
            currentManualStep={activeStep}
            saveCategory={saveCategory}
            savePrompt={savePrompt}
            deleteCategory={deleteCategory}
            initialValues={initialValues.categories}
            formikField={"category"}
          />
        );
      case SidebarSubitems.DOCUMENT_SCRAPERS:
        return (
          <DocumentScraperCard
            currentManualStep={SidebarSubitems.DOCUMENT_SCRAPERS}
            initialValues={initialValues?.documentScrapers?.scrapedInfo}
            chatbotID={chatbotID}
            onDocumentUpload={handleDocumentUpload}
            onDocumentDelete={handleDocumentDelete}
            onDocumentsUpdate={() =>
              setInitialValues((prev) => ({
                ...prev,
                documentScrapers: { scrapedInfo: [] },
              }))
            }
          />
        );
      case SidebarSubitems.WEB_SCRAPERS:
        return (
          <WebScraperCard
            currentManualStep={SidebarSubitems.WEB_SCRAPERS}
            initialValues={initialValues?.webScrapers?.scrapedUrls}
            chatbotID={chatbotID}
            onWebsiteScrape={handleWebsiteScrape}
            onWebsiteDelete={handleWebsiteDelete}
          />
        );
      case SidebarSubitems.LIVE_APIS:
        return (
          <LiveApiCard
            setActiveApiValues={updateActiveApiValues}
            initialValues={initialValues.activeApi}
            currentManualStep={SidebarSubitems.LIVE_APIS}
            updateActiveApiValues={updateActiveApiValues}
            onSubmit={handleSubmit}
          />
        );
      case SidebarSubitems.MODEL_POWER:
        return (
          <ModelPowerCard
            setGptModelValues={setInitialValues}
            onSubmit={handleSubmit}
            initialValues={initialValues.gptModel}
            currentManualStep={SidebarSubitems.MODEL_POWER}
          />
        );
      case SidebarSubitems.LAYOUT_CONFIGURATION:
        return (
          <LayoutCard
            setDesignValues={setInitialValues}
            onSubmit={saveLayoutConfiguration}
            initialValues={initialValues.layoutConfiguration[0]}
            currentManualStep={SidebarSubitems.LAYOUT_CONFIGURATION}
          />
        );
      case SidebarSubitems.INTERACTIVE_TEST:
        return (
          <InteractiveTesterCard
            chatbotID={chatbotID}
            setInteractiveTesterValues={setInitialValues}
            onSubmit={handleSubmit}
            initialValues={initialValues.interactiveTester}
            currentManualStep={SidebarSubitems.INTERACTIVE_TEST}
          />
        );
      case SidebarSubitems.ACTIVATE_CHATBOT:
        return (
          <ActivationCard
            initialValues={initialValues}
            chatbotID={chatbotID}
            currentManualStep={SidebarSubitems.ACTIVATE_CHATBOT}
          />
        );
      default:
        return (
          <IntelligenceCard
            currentManualStep='Persoonlijkheid'
            placeholder='In het kort over het persoonlijkheid van je chatbot..'
            formikField='personality'
          />
        );
    }
  };

  return (
    <>
      {isLoading ? (
        <GeneralLoader />
      ) : (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
          {({ setFieldValue, values, dirty }) => {
            checkForUnsavedChanges(values);
            return (
              <Form>
                <div key={activeStep}>{renderFormSection(setFieldValue, values, dirty, saveCategory)}</div>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EditBot;
