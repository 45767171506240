import React, { useRef, useState, useEffect } from "react";
import { CheckIconCircle, UploadIcon } from "../../assets/svgs/SvgIcons";
import "../../css/PrivateRoutes/AvatarUpload.css";

const AvatarUpload = ({
  name,
  avatarUploadText,
  setBase64Image,
  setDesignValues,
  initialImage
}) => {
  const fileInputRef = useRef(null);
  const [fileDropped, setFileDropped] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];

  useEffect(() => {
    if (initialImage) {
      setIsUploaded(true);
      setFileDropped(true);
    }
  }, [initialImage]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && acceptedFormats.includes(file.type)) {
      setFileDropped(true);

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setBase64Image(base64Data);
        setDesignValues((prevValues) => {
          return {
            ...prevValues,
            [name]: base64Data,
          };
        });
        setIsUploaded(true);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (file && acceptedFormats.includes(file.type)) {
      setFileDropped(true);

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result;
        setBase64Image(base64Data);
        setDesignValues((prevValues) => {
          return {
            ...prevValues,
            [name]: base64Data,
          };
        });
        setIsUploaded(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      className={`avatar-upload-upload ${fileDropped ? "file-dropped" : ""}`}
    >
      <span>{avatarUploadText}</span>
      <div
        className={`mt-2 file-upload-label ${fileDropped ? "highlight" : ""} ${
          isUploaded ? "uploaded" : ""
        }`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
          backgroundImage: `url(${initialImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <input
          type="file"
          name={name}
          accept=".jpg, .jpeg, .png"
          id="avatarUpload"
          ref={fileInputRef}
          className="avatar-upload-drop-input"
          onChange={handleFileChange}
        />
        <label htmlFor="file" className="file-upload-design">
          {isUploaded ? (
            <div className="uploaded-icon my-3">
              Geupload
              <CheckIconCircle width="50px" height="50px" color="#26C87C" />
            </div>
          ) : (
            <div className="uploaded-icon my-3">
              <UploadIcon width="50px" height="50px" color="#1E3050" />
            </div>
          )}
          {!isUploaded && (
            <>
              <p>Drag and Drop</p>
              <p>or</p>
            </>
          )}
          <span
            className="browse-button"
            theme="dark"
            size="m"
            onClick={handleButtonClick}
          >
            {isUploaded ? "Opnieuw kiezen" : "Bestand kiezen"}
          </span>
        </label>
      </div>
    </div>
  );
};

export default AvatarUpload;
