import React from "react";
import { UserGroupIcon } from "../assets/svgs/SvgIcons";
import "../css/CardLabelEmpList.css";

const CardLabelEmpList = () => {
  return (
    <div className="d-flex align-items-center">
      <UserGroupIcon width="40" className="icon-class" />
      <span className="card-title user-title">Medewerkers</span>{" "}
    </div>
  );
};

export default CardLabelEmpList;
