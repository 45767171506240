// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title-inbox h6 {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 700;
  color: var(--rbl-white);
}`, "",{"version":3,"sources":["webpack://./src/css/InboxSectionTitle.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;AACJ","sourcesContent":[".section-title-inbox h6{\n    text-transform: uppercase;\n    font-size: 0.7em;\n    font-weight: 700;\n    color: var(--rbl-white)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
