// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionMenu.css */

.action-menu-container {
    position: relative;
    display: inline-block;
}

.action-menu-icon {
    color: grey;
    cursor: pointer;
}

.action-menu-icon:hover {
    color: black;
}

.action-menu-dropdown {
    position: absolute;
    top: -40px;
    transform: translateY(10px);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 100;
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    z-index: 99999 !important;
}

.action-menu-dropdown button {
    display: block;
    background: none;
    border: none;
    padding: 5px 10px;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.action-menu-dropdown button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 8px 12px;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.action-menu-dropdown button .icon {
    margin-right: 8px;
}

/* Add styling for hover effects */
.action-menu-dropdown button:hover {
    background-color: #f2f2f2;
}

.icon {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/DocumentActionMenu.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,2BAA2B;IAC3B,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA,kCAAkC;AAClC;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["/* ActionMenu.css */\n\n.action-menu-container {\n    position: relative;\n    display: inline-block;\n}\n\n.action-menu-icon {\n    color: grey;\n    cursor: pointer;\n}\n\n.action-menu-icon:hover {\n    color: black;\n}\n\n.action-menu-dropdown {\n    position: absolute;\n    top: -40px;\n    transform: translateY(10px);\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    z-index: 100;\n    background-color: white;\n    border-radius: 4px;\n    padding: 10px;\n    z-index: 99999 !important;\n}\n\n.action-menu-dropdown button {\n    display: block;\n    background: none;\n    border: none;\n    padding: 5px 10px;\n    text-align: left;\n    width: 100%;\n    cursor: pointer;\n}\n\n.action-menu-dropdown button {\n    display: flex;\n    align-items: center;\n    background: none;\n    border: none;\n    padding: 8px 12px;\n    width: 100%;\n    text-align: left;\n    cursor: pointer;\n}\n\n.action-menu-dropdown button .icon {\n    margin-right: 8px;\n}\n\n/* Add styling for hover effects */\n.action-menu-dropdown button:hover {\n    background-color: #f2f2f2;\n}\n\n.icon {\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
