import React from "react";
import { useField } from "formik";
import "./css/FormikRestrictionsTextArea.css";

export const FormikRestrictionsTextArea = ({ name, minHeight, width, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <div className="restriction-input form-group">
      <textarea
        {...field}
        {...props}
        className={`formik-field form-control ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
        style={{
          minHeight: minHeight || "250px", 
          width: width || "100%",
        }}
      />
      {meta.touched && meta.error && (
        <div className="text-danger">{meta.error}</div>
      )}
    </div>
  );
};
