import React, { useState, useEffect } from "react";
import "../../css/PrivateRoutes/RestrictionsCard.css";
import { FormikRestrictionsTextArea } from "../../formik/components/FormikRestrictionsTextArea";
import { TooltipContent } from "../../helpers/Enums";
import Tooltip from "../../helpers/Tooltip";
import ActionMenu from "../ActionMenu";
import RobolButton from "../RobolButton";
import CardWrapper from "./CardWrapper";

const RestrictionsCard = ({
  currentManualStep,
  saveRestrictions,
  initialValues,
  deleteRestriction,
  editRestriction,
}) => {
  const [newRestriction, setNewRestriction] = useState("");
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    console.log("RestrictionsCard received new props: ", initialValues);
  }, [initialValues]);

  const handleAddNewRestriction = () => {
    if (newRestriction.trim() !== "") {
      saveRestrictions(newRestriction);
      setNewRestriction("");
    }
  };

  const handleEditRestriction = (index, value) => {
    editRestriction(initialValues.list[index].id, value);
    setEditIndex(null);
    setNewRestriction("");
  };

  return (
    <div className="row">
      <div className="block-margin"></div>
      <div className="col">
        <CardWrapper className="mb-2" minHeight="50vh">
          <div className="user-card">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h5 className="intelligence-cards-title d-flex align-items-center">
                  {currentManualStep}
                  <div className="ml-2">
                    <Tooltip toolTip={TooltipContent.RESTRICTIONS} />
                  </div>
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr className="black" />
              </div>
            </div>

            <>
              <FormikRestrictionsTextArea
                disabled={editIndex !== null}
                name="restrictionsTextArea"
                minHeight="100px"
                onChange={(e) => setNewRestriction(e.target.value)}
                value={
                  editIndex !== null
                    ? "Verstrek nooit informatie over onze concurrenten!"
                    : newRestriction
                }
                placeholder="Verstrek nooit informatie over onze concurrenten!"
              />
            </>
            <div className="mt-3 row">
              <div className="col-md-auto">
                <RobolButton
                  onClick={handleAddNewRestriction}
                  theme="dark"
                  size="m"
                >
                  Restrictie toevoegen
                </RobolButton>
              </div>
            </div>
            {initialValues.list?.map((restriction, index) => (
              <div className="mt-3" key={index}>
                <div className="restriction-item d-flex justify-content-between align-items-center">
                  {editIndex === index ? (
                    <input
                      type="text"
                      value={newRestriction}
                      onChange={(e) => setNewRestriction(e.target.value)}
                      onBlur={() =>
                        handleEditRestriction(index, newRestriction)
                      }
                      autoFocus
                    />
                  ) : (
                    <span>{restriction.text}</span>
                  )}
                  <ActionMenu
                    isOpen={menuOpenIndex === index}
                    toggleMenu={() =>
                      setMenuOpenIndex((prevIndex) =>
                        prevIndex === index ? null : index
                      )
                    }
                    userId={index}
                    onEdit={() => {
                      setEditIndex(index);
                      setNewRestriction(initialValues.list[index].text);
                    }}
                    onDelete={() =>
                      deleteRestriction(initialValues.list[index].id)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </CardWrapper>
      </div>
    </div>
  );
};

export default RestrictionsCard;
