import { Form } from "formik";
import React, { useState } from "react";
import "../../css/PrivateRoutes/ActivationCard.css";
import RobolButton from "../RobolButton";
import CardWrapper from "./CardWrapper";

const ActivationCard = ({ currentManualStep, chatbotID, initialValues }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = () => {
    const codeContent = `
    <div id="chatbot-container"></div>
    <script>
      (function() {
        const chatbotID = "${chatbotID}"; // Each client replaces this with their unique chatbot ID
        const chatbotAppURL = \`https://widget.robol-ai.chat/index.html?chatbotID=\${chatbotID}\`;
        
        const iframe = document.createElement("iframe");
        iframe.src = chatbotAppURL;
        iframe.style.position = "fixed";
        iframe.style.bottom = "0";
        iframe.style.right = "0";
        iframe.style.width = "100%";
        iframe.style.height = "100px";
        iframe.style.border = "none";
        iframe.style.zIndex = "9999";
        iframe.style.background = "transparent";
        iframe.allowTransparency = "true";
        iframe.onload = function () {
          console.log("Chatbot iframe loaded");
        };

        const targetDiv = document.getElementById("chatbot-container");
        if (targetDiv) {
          targetDiv.appendChild(iframe);
        } else {
          console.error("Element with id 'chatbot-container' not found.");
        }

        // Listen for messages from the iframe to adjust the height
        window.addEventListener('message', function(event) {
          if (event.data === 'chatbot-open') {
            iframe.style.height = '100%';
          } else if (event.data === 'chatbot-close') {
            iframe.style.height = '100px';
          }
        }, false);
      })();
    </script>
    `;

    navigator.clipboard.writeText(codeContent).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  return (
    <>
      <div className="row">
        <div className="block-margin"></div>
        <div className="col">
          <CardWrapper>
            <div className="user-card">
              <div className="row">
                <div className="col">
                  <h5 className="intelligence-cards-title">
                    {currentManualStep}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <hr className="black" />
                </div>
              </div>
              <Form>
                <div className="row">
                  <div className="col-12">
                    <h4>Gefeliciteerd! Jouw bot is LIVE.</h4>
                    <p>
                      Om je chatbot te integreren met je website, plak je het
                      onderstaande script in de <code>&lt;body&gt;</code> van je
                      HTML-document of in een HTML-blok op je website.
                    </p>

                    <div className="code-content terminal p-5">
                      {`
<div id="chatbot-container"></div>
<script>
  (function() {
    const chatbotID = "${chatbotID}"; 
    const chatbotAppURL = \`https://widget.robol-ai.chat/index.html?chatbotID=\${chatbotID}\`;

    const iframe = document.createElement("iframe");
    iframe.src = chatbotAppURL;
    iframe.style.position = "fixed";
    iframe.style.bottom = "0";
    iframe.style.right = "0";
    iframe.style.width = "100%";
    iframe.style.height = "100px";
    iframe.style.border = "none";
    iframe.style.zIndex = "9999";
    iframe.style.background = "transparent";
    iframe.allowTransparency = "true";
    iframe.onload = function () {
      console.log("Chatbot iframe loaded");
    };

    const targetDiv = document.getElementById("chatbot-container");
    if (targetDiv) {
      targetDiv.appendChild(iframe);
    } else {
      console.error("Element with id 'chatbot-container' not found.");
    }

    window.addEventListener('message', function(event) {
      if (event.data === 'chatbot-open') {
        iframe.style.height = '100%';
      } else if (event.data === 'chatbot-close') {
        iframe.style.height = '100px';
      }
    }, false);
  })();
</script>
                        `}
                    </div>
                    <RobolButton
                      theme="green"
                      onClick={handleCopyToClipboard}
                      disabled={isCopied}
                    >
                      {isCopied ? "Gekopieerd!" : "Kopieer script"}
                    </RobolButton>
                  </div>
                </div>
              </Form>
            </div>
          </CardWrapper>
        </div>
      </div>
    </>
  );
};

export default ActivationCard;
