import React from "react";
import RobolButton from "./RobolButton";
import { AddUserIcon } from "../assets/svgs/SvgIcons";
import { useNavigate } from "react-router-dom";

const AddUserButton = ({ text, theme, size, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("../admin/employee/add");
  };

  return (
    <RobolButton theme={theme} size={size} onClick={handleClick}>
      <div className="d-flex align-items-center">
        <AddUserIcon
          fill="#FFF"
          width="18"
          height="18"
          className="icon-class"
        />
        <span className="ml-2 ms-2 ms-2 d-none d-lg-inline">{text}</span>
      </div>
    </RobolButton>
  );
};

export default AddUserButton;
