import { baseUrl } from "./getBaseUrl";

export const SubButton = () => {
  const baseurl = baseUrl();
  return (
    <section>
      <form
        action={`https://www.robol-ai.chat/api/create-checkout-session/price_1NhrXJFrvQDrlyA7jbk00G2B`}
        method="POST"
      >
        <button type="submit" className="btn custom-btn-primary my-3">
          Abonneren
        </button>
      </form>
    </section>
  );
};
