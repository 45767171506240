import React, { useEffect, useState } from "react";
import {
  ConversationClosed,
  ForwardingStatus,
  MessagesStatus,
} from "../../helpers/Enums";
import { EmailListItem } from "./EmailListItem";
import { FilterIconDownUp, FilterIconUpDown } from "../../assets/svgs/SvgIcons";
import { IconColor } from "../../assets/svgs/SvgIconColors";

export const EmailList = ({
  conversations,
  onEmailSelected,
  selectedEmailId,
  currentSection,
  isMobile,
  selectedConversation,
  selectedEmail,
  setSelectedConversation,
  setShowSortOptions,
  showSortOptions,
  selectedOption,
  sortOrder,
  setSortOrder,
}) => {
  const [filteredConversations, setFilteredConversations] = useState([]);

  const handleFilterIconClick = () => {
    setShowSortOptions(!showSortOptions);
    setSortOrder(sortOrder === "Nieuw-Oud" ? "Oud-Nieuw" : "Nieuw-Oud");
  };


  useEffect(() => {
    // Sort conversations based on sortOrder
    let sortedConversations = [...conversations];
    sortedConversations.sort((a, b) => {
      if (sortOrder === "Nieuw-Oud") {
        return new Date(b.started_at) - new Date(a.started_at);
      } else if (sortOrder === "Oud-Nieuw") {
        return new Date(a.started_at) - new Date(b.started_at);
      }
      return 0;
    });

    // Filter conversations based on the current section
    let filteredConversations = sortedConversations;
    if (currentSection === "unread") {
      filteredConversations = sortedConversations.filter(
        (conversation) => conversation.is_read === MessagesStatus.IS_NOT_READ
      );
    } else if (currentSection === "read") {
      filteredConversations = sortedConversations.filter(
        (conversation) => conversation.is_read === MessagesStatus.IS_READ
      );
    } else if (currentSection === "forward") {
      filteredConversations = sortedConversations.filter(
        (conversation) =>
          conversation.forward === ForwardingStatus.FORWARD_REQUESTED
      );
    } else if (currentSection === "closed") {
      filteredConversations = sortedConversations.filter(
        (conversation) =>
          conversation.conversation_closed === ConversationClosed.IS_CLOSED
      );
    } else if (currentSection === "user") {
      filteredConversations = sortedConversations.filter(
        (conversation) => conversation.forwarded_to === selectedEmail
      );
    }

    setFilteredConversations(filteredConversations);
  }, [currentSection, conversations, selectedEmail, sortOrder]);

  useEffect(() => {
    setSelectedConversation(null);
  }, [currentSection]);

  if (!filteredConversations || filteredConversations.length === 0) {
    return <div className="email-list empty">Je bent helemaal bij!</div>;
  }

  return (
    <div className={`${isMobile ? "email-list-mobile" : "email-list"}`}>
      <div className="container-fluid filter-block">
        <div className="row py-3 align-items-center">
          <div className="col d-flex align-items-center"></div>
          <div className="col-auto">
            {sortOrder === "Nieuw-Oud" ? (
              <>
                <span className="sorting-title">
                  <b className="mr-3">Van nieuw naar oud</b>
                </span>
                <span onClick={handleFilterIconClick}>
                  <FilterIconDownUp
                    color={
                      selectedOption ? IconColor.ACCENT : IconColor.PRIMARY
                    }
                    height="20"
                    width="20"
                  />
                </span>
              </>
            ) : (
              <>
                <span className="sorting-title">
                  <b className="mr-3">Van oud naar nieuw</b>
                </span>
                <span onClick={handleFilterIconClick}>
                  <FilterIconUpDown
                    color={
                      selectedOption ? IconColor.ACCENT : IconColor.PRIMARY
                    }
                    height="20"
                    width="20"
                  />
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      {filteredConversations.map((conversation) => (
        <EmailListItem
          selectedConversation={selectedConversation}
          key={conversation.id}
          conversation={conversation}
          onEmailClicked={() => onEmailSelected(conversation)}
          selected={selectedEmailId === conversation.id}
        />
      ))}
    </div>
  );
};
