import { useField } from "formik";
import React, { useEffect } from "react";
import "./css/FormikInput.css";

import { useTranslation } from "react-i18next";
import _objectWithoutPropertiesLoose from "./_objectWithoutPropertiesLoose";
import InputField from "./InputField";

export const FormikInput = (_ref) => {
  const {
    name,
    inputOptions = {},
    required = false,
    onBlur = () => {},
    onChange = () => {},
    className,
  } = _ref;
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const props = _objectWithoutPropertiesLoose(_ref, [
    "onBlur",
    "onChange",
    "inputOptions",
  ]);

  console.log("name", name);

  useEffect(() => {
    onChange(field.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <div>
      <InputField
        className={className}
        label={`${t(`${name}`)}${required ? "*" : ""}`}
        {...props}
        inputOptions={inputOptions}
        onBlur={(e) => {
          field.onBlur(e);
          onBlur(e);
        }}
        onChange={field.onChange}
        value={field.value}
        isInvalid={!!meta.error && meta.touched}
        isInvalidFeedback={meta.error}
        isValid={!meta.error && (field.value || field.value === 0)}
      />
    </div>
  );
};
