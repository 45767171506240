import React from "react";
import "../../css/PrivateRoutes/AdminDashboardCards.css";
import RobolButton from "../RobolButton";
import { AddPlusIcon } from "../../assets/svgs/SvgIcons";

const NewEmployeeBotCard = ({ type, handleAddButtonClick }) => {
  // Define gradients for employee and bot
  const employeeGradient = {
    background: `linear-gradient(180deg, #0F2027 0%, #003366 100%)`,
  };

  const botGradient = {
    background: `linear-gradient(180deg, #31C580 0%, #003366 100%)`,
  };

  const cardTitle =
    type === "employee"
      ? "Nieuwe medewerker toevoegen"
      : "Nieuwe AI-bot toevoegen";
  const cardText =
    type === "employee"
      ? "Maak je team compleet!"
      : "Verbeter je automatisering met een nieuwe bot!";

  const gradientStyle = type === "employee" ? employeeGradient : botGradient;

  return (
    <div className="edit-bot-card" onClick={handleAddButtonClick}>
      <div className="card">
        <div className="card-img-top" style={gradientStyle}>
          <div className="icon-container">
            <AddPlusIcon className="add-icon" />
          </div>
        </div>
        <div className="card-body">
          <h5 className="card-title text-center mt-5">{cardTitle}</h5>
          <p className="card-text text-center">{cardText}</p>
          {/* <div className="buttons-container d-flex justify-content-around">
            <RobolButton
              theme="green"
              size="xl"
              onClick={(e) => e.stopPropagation()}
            >
              Hulp nodig
            </RobolButton>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default NewEmployeeBotCard;
