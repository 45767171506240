import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { GeneralLoader } from "../../components/GeneralLoader";
import { useAuth } from "../../context/AuthContext";
import { useWebSocket } from "../../context/WebSocketContext";
import "../../css/PrivateRoutes/UserOverview.css";
import { auth } from "../../firebase";
import {
  getAvailability,
  getDashboardOverviewStatistics,
  toggleUserActiveStatus,
} from "../../services/api/UserServices";
import { AiStats } from "./AiStats";
import { LiveMessages } from "./LiveMessages";
import { PersonalAssistant } from "./PersonalAssistant";
import { UserOverviewHeader } from "./UserOverviewHeader";

const UserOverview = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [copyMessageToAiAssistent, setCopyMessageToAiAssistent] = useState("");
  const [isAiTyping, setIsAiTyping] = useState(false);
  const [availability, setAvailability] = useState(false);
  const [employeeName, setEmployeeName] = useState([]);
  const [statistics, setStatistics] = useState(false);
  const { currentUser } = useAuth();
  const [latestMessages, setLatestMessages] = useState([]);
  const socket = useWebSocket();

  useEffect(() => {
    if (!socket) return;

    const handleWebSocketMessages = (event) => {
      try {
        const receivedData = JSON.parse(event.data);
        if (
          receivedData.type === "conversationData" &&
          Array.isArray(receivedData.data)
        ) {
          const updatedMessages = receivedData.data.map((conversation) => {
            const latestMessage = conversation.conversation_data.slice(-1)[0];
            const senderRole = latestMessage.role;
            const senderInfo =
              senderRole === "assistant"
                ? "AI-bot"
                : senderRole === "user"
                ? "(Klant op de website)"
                : `(Medewerker: ${conversation.forwarded_to})`;

            return {
              ...latestMessage,
              senderInfo,
              started_at: conversation.started_at,
              forwarded_to: conversation.forwarded_to,
            };
          });

          // Sort messages by started_at in descending order (newest first)
          updatedMessages.sort(
            (a, b) => new Date(b.started_at) - new Date(a.started_at)
          );
          // Take only the first 5 messages
          const limitedMessages = updatedMessages.slice(0, 4);

          setLatestMessages(limitedMessages);
        } else {
          console.error(
            "Received data is not in the expected format:",
            receivedData
          );
        }
      } catch (error) {
        console.error("Error parsing WebSocket data:", error);
      }
    };

    socket.onmessage = handleWebSocketMessages;

    return () => {
      socket.onmessage = null;
    };
  }, [socket]);

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const data = await getAvailability(currentUser?.email, token);
        setAvailability(data.is_active);
        setEmployeeName(data);
      } catch (error) {
        console.error("Error fetching availability:", error.message);
      }
    };

    fetchAvailability();
  }, []);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const token = await auth.currentUser.getIdToken(true);
        const data = await getDashboardOverviewStatistics(
          currentUser?.email,
          token
        );
        setStatistics(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching availability:", error.message);
        setIsLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  const initialValues = {
    available: availability,
  };

  const handleSubmit = async () => {
    try {
      const token = await auth.currentUser.getIdToken(true);
      await toggleUserActiveStatus(currentUser?.email, token);
      const updatedAvailability = await getAvailability(
        currentUser?.email,
        token
      );
      setAvailability(updatedAvailability.is_active);
      console.log("User active status toggled successfully");

      // Refetch statistics data after toggling availability
      const statisticsData = await getDashboardOverviewStatistics(
        currentUser?.email,
        token
      );
      setStatistics(statisticsData);
    } catch (error) {
      console.error("Error toggling user active status:", error.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      handleSubmit(values);
    },
  });

  if (isLoading) {
    return <GeneralLoader />;
  }
  return (
    <FormikProvider value={formik}>
      <UserOverviewHeader
        first_name={employeeName?.first_name}
        last_name={employeeName?.last_name}
        toggleSubmit={formik.submitForm}
        picture={employeeName?.picture}
      />
      <div className="row mb-4">
        <div className="col-lg-6 d-flex flex-column">
          <div className="row">
            <div className="col-12">
              <AiStats
                average_acceptance_time={statistics?.average_acceptance_time}
                average_chat_duration={statistics?.average_chat_duration}
                conversations_today={statistics?.conversations_today}
                users_available={statistics?.users_available}
                users_total={statistics?.users_total}
              />
            </div>
          </div>
          <div className="row mt-3 flex-grow-1">
            <div className="col-12">
              <PersonalAssistant
                copyMessageToAiAssistent={copyMessageToAiAssistent}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 mt-3 mt-lg-0">
          <LiveMessages
            setCopyMessageToAiAssistent={setCopyMessageToAiAssistent}
            isAiTyping={isAiTyping}
            latestMessages={latestMessages}
            setLatestMessages={setLatestMessages}
          />
        </div>
      </div>
    </FormikProvider>
  );
};

export default UserOverview;
