import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "../App.css";
import { useAuth } from "../context/AuthContext";
import { WebSocketProvider } from "../context/WebSocketContext";
import "../css/PrivateRoutes/PrivateRoute.css";
import { UserRoles } from "../helpers/Enums";
import AdminDashboard from "../pages/dashboard/AdminDashboard";
import PreAdminDashboard from "../pages/dashboard/PreAdminDashboard";
import MessagesHub from "../pages/messageshub/MessagesHub";
import UserOverview from "../pages/userOverview/UserOverview";
import { getUserRole } from "../services/api/UserServices";
import EditBot from "./EditBot";
import { GeneralLoader } from "./GeneralLoader";
import AddBot from "./PrivateRoutes/AddBot";
import AddEmployee from "./PrivateRoutes/AddEmployee";
import AdminEditEmployee from "./PrivateRoutes/AdminEditEmployee";
import EditEmployee from "./PrivateRoutes/EditEmployee";

export const PrivateRoute = ({ role, setRole, loading }) => {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    return <Navigate to="/signin" replace />;
  }

  if (loading) {
    return <GeneralLoader />;
  }
  const pagePaths = {
    dashboard: ["/dashboard", "/dashboard/user/overview"],
    editBot: ["/dashboard/admin/bot/edit/:chatbotID"],
    messagesHub: [
      "/dashboard/user/messageshub",
      "/dashboard/user/messageshub/",
    ],
  };

  const currentPage = Object.entries(pagePaths).find(([key, paths]) =>
    paths.some((path) => {
      const regex = new RegExp(path.replace(/:\w+/g, "\\w+"));
      return regex.test(location.pathname);
    })
  );

  if (!currentUser) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div className="content-wrapper">
      <div
        className={
          currentPage && currentPage[0] === "editBot"
            ? "edit-page-position"
            : "dashboard-position"
        }
      >
        <div className="content">
          <div className={currentPage ? "container-fluid" : "container"}>
            <WebSocketProvider>
              <Routes>
                {role === UserRoles.ADMIN && (
                  <>
                    <Route path="/" element={<PreAdminDashboard />} />
                    <Route path="/new" element={<AdminDashboard />} />
                    <Route
                      path="/admin/bot/edit/:chatbotID"
                      element={<EditBot />}
                    />
                    <Route
                      path="/admin/employee/add"
                      element={<AddEmployee />}
                    />
                    <Route
                      path="/admin/employee/edit/:employeeID"
                      element={<EditEmployee />}
                    />
                    <Route
                      path="/admin/employee/list"
                      element={<AdminEditEmployee />}
                    />

                    <Route path="/user/overview" element={<UserOverview />} />
                    <Route
                      path="/user/messageshub"
                      element={<MessagesHub role={role} />}
                    />
                    <Route path="/admin/bot/add" element={<AddBot />} />
                  </>
                )}

                {role === UserRoles.USER && (
                  <>
                    <Route path="/" element={<UserOverview />} />
                    <Route
                      path="/user/messagesHub"
                      element={<MessagesHub role={role} />}
                    />
                  </>
                )}
              </Routes>
            </WebSocketProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
