import React, { useState, useEffect } from "react";
import image_bg from "../../assets/demo/fitpreps.png";
import image_bg_mobile from "../../assets/demo/dutchtaxadvice.png";

export const FitPreps = () => {
  // State to keep track of window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Listen for window resize events
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Conditionally set background image based on window width
  const bgStyle = {
    background: `url(${
      windowWidth <= 768 ? image_bg_mobile : image_bg
    }) no-repeat center center fixed`,
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    backgroundSize: "cover",
    height: "100vh",
    width: "100%",
  };

  // Inject chatbot script
  useEffect(() => {
    // Set the chatbot ID
    window.chatbotID = "7652f99a-24ac-4014-b562-855d28a46b05";

    // Create a script element
    const scriptElement = document.createElement("script");
    scriptElement.src = "https://widget.robol-ai.chat/static/js/bundle.js";
    scriptElement.async = true;

    // Append the script element to the DOM (in this case, div with id "widget-root")
    const widgetRoot = document.getElementById("widget-root");
    if (widgetRoot) {
      widgetRoot.appendChild(scriptElement);
    }

    // Cleanup: Remove script element from DOM
    return () => {
      if (widgetRoot) {
        widgetRoot.removeChild(scriptElement);
      }
    };
  }, []);

  return (
    <>
      <div id="widget-root"></div>
      <div style={bgStyle}>{/* your other components here */}</div>
    </>
  );
};
