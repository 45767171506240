import { useFormikContext } from "formik";
import React from "react";
import "../../css/PrivateRoutes/IntelligenceCard.css";
import CardWrapper from "./CardWrapper";
import { TooltipContent } from "../../helpers/Enums";
import Tooltip from "../../helpers/Tooltip";

const IntelligenceCard = ({ currentManualStep, placeholder, formikField }) => {
  const { values, setFieldValue } = useFormikContext();
  const tooltipContent = {
    Persoonlijkheid: TooltipContent.PERSONALITY,
    Bedrijf: TooltipContent.COMPANY,
  };
  const objectPath =
    currentManualStep === "Persoonlijkheid" ? "personality" : "company";

  const handleChange = (e) => {
    setFieldValue(`${formikField}.${objectPath}`, e.target.value);
    setFieldValue(`${formikField}.isTouched`, true);
    setFieldValue(`${formikField}.isSaved`, false);
  };

  return (
    <div className="row">
      <div className="block-margin"></div>
      <div className="col">
        <CardWrapper>
          <div className="user-card">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h5 className="intelligence-cards-title d-flex align-items-center">
                  {currentManualStep}
                  <div className="ml-2">
                    <Tooltip toolTip={tooltipContent[currentManualStep]} />
                  </div>
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <hr className="black" />
              </div>
            </div>
            <textarea
              name={`${formikField}.${objectPath}`}
              placeholder={placeholder}
              value={values[formikField][objectPath]}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        </CardWrapper>
      </div>
    </div>
  );
};

export default IntelligenceCard;
