import { useEffect } from "react";
import { TooltipContent } from "../../helpers/Enums";
import RobolButton from "../RobolButton";
import CardWrapper from "./CardWrapper";
import Tooltip from "../../helpers/Tooltip";

const InteractiveTesterCard = ({
  initialValues,
  setDesignValues,
  currentManualStep,
  onSubmit,
  chatbotID, // Assuming this is passed, though it's not used in the script directly
}) => {
  useEffect(() => {
    const botId = chatbotID; // Each client replaces this with their unique chatbot ID
    const chatbotAppURL = `https://widget.robol-ai.chat/index.html?chatbotID=${botId}`;

    function loadChatbot(divId) {
      const iframe = document.createElement("iframe");
      iframe.src = chatbotAppURL;
      iframe.style.width = "100%";
      iframe.style.minHeight = "750px";
      iframe.style.maxHeight = "850px";
      iframe.style.zIndex = "9999";
      iframe.style.background = "transparent";
      iframe.style.borderRadius = "8px"; // Rounded corners
      iframe.style.boxShadow = "0px 20px 40px rgba(0, 0, 0, 0.05)"; // Soft shadow
      iframe.style.border = "2px solid #CCCCCC"; // Light grey border with a thickness of 2px
      iframe.style.backgroundColor = "#FFFFFF"; // White background inside the iframe

      iframe.onload = function () {
        console.log("Chatbot iframe loaded");
      };

      const targetDiv = document.getElementById(divId);
      if (targetDiv) {
        targetDiv.appendChild(iframe);
      } else {
        console.error(`Element with id '${divId}' not found.`);
      }

      // Listen for messages from the iframe to adjust the height
      window.addEventListener(
        "message",
        function (event) {
          console.log("event.data", event.data);
          if (event.data === "chatbot-open") {
            iframe.style.height = "600px";
          } else if (event.data === "chatbot-close") {
            iframe.style.height = "50px";
          }
        },
        false
      );
    }

    // Load the chatbot into the 'chatbot-container' div
    loadChatbot("chatbot-container");
  }, []); // Empty dependency array means this runs only once on mount

  return (
    <>
      <div className="row">
        <div className="block-margin"></div>
        <div className="col pb-5">
          <CardWrapper minHeight="70vh" className="mb-2">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h5 className="intelligence-cards-title d-flex align-items-center">
                  {currentManualStep}
                  <div className="ml-2">
                    <Tooltip toolTip={TooltipContent.INTERACTIVE_TEST} />
                  </div>
                </h5>
              </div>
            </div>
            <hr className="black" />
            <h4>Dit is een live-voorbeeld van je chatbot.</h4>
            <b> Druk op de chatbot-icoon om te starten. </b>
            <hr/>
            Houd bij het testen rekening met de volgende punten:
            <ul>
              <li>Reageert de bot op de ingevoerde informatie</li>
              <li>
                Komen de informatie uit geuploade documenten terug in gesprek
              </li>
              <li>
                Komen de informatie uit geuploade gescrapde websites terug in
                gesprek
              </li>
              <li>Ben je tevreden met de kwaliteit van de antwoorden</li>
            </ul>
            <div className="row justify-content-center">
              <div className="col-12 mb-5">
                <div className="row">
                  <div className="col-md-12">
                    {/* Container where the chatbot iframe will be rendered */}
                    <div id="chatbot-container"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-start mt-3">
                    <p>
                      Als je met alles tevreden bent, kan je de testfase
                      afronden, je zal dan een speciale script krijgen die je
                      kan plakken op je website of binnen een applicatie.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-start mt-3">
                    <RobolButton
                      onClick={() => onSubmit(initialValues)}
                      theme="dark"
                    >
                      Testfase afronden
                    </RobolButton>
                  </div>
                </div>
              </div>
            </div>
          </CardWrapper>
        </div>
      </div>
    </>
  );
};

export default InteractiveTesterCard;
