import React, { useState } from "react";
import { resetPassword } from "../components/FirebaseMethods";
import { useNavigate } from "react-router-dom";

export const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      await resetPassword(email);
      console.log("Password reset email sent successfully!");
      navigate("/signin"); // Redirect to signin page after successful reset
    } catch (error) {
      console.error("Password reset error:", error);
      setError("Password reset email could not be sent.");
    }
  };

  return (
    <div className="container h-100 main-container fade-in-image signin-container">
      <div className="row align-items-center justify-content-center h-100">
        <div className="col-md-6">
          <div className="login-form-container p-4">
            <h1 className="mb-4">Wachtwoord Resetten</h1>
            {error && <p className="text-danger">{error}</p>}
            <div className="form-group">
              <label htmlFor="resetEmail">E-mailadres:</label>
              <input
                type="email"
                className="form-control"
                id="resetEmail"
                placeholder="Voer uw e-mailadres in"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="w-100 btn custom-btn-primary"
              onClick={handleResetPassword}
            >
              Reset Wachtwoord
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
