import React, { useState, useEffect, useRef } from "react";
import { Field } from "formik";
import { SketchPicker } from "react-color";
import "./css/FormikInputColor.css";
import RobolButton from "../../components/RobolButton";

const FormikInputColor = ({ label, name, value, onChange, onConfirm, postfix }) => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState(value);
  const colorPickerRef = useRef(null);

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    onChange(color, name); // Poprawione przekazywanie parametrów
  };

  const handleReset = () => {
    setSelectedColor(value);
    onChange({ hex: value }, name);
    setColorPickerVisible(false);
  };

  const handleConfirmClick = () => {
    onConfirm({ hex: selectedColor }); // Przekazujemy obiekt z wartością koloru
    setColorPickerVisible(false);
  };

  const handleInputChange = (event) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
    onChange({ hex: newColor }, name); // Poprawione przekazywanie parametrów
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setColorPickerVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Field name={name}>
      {({ field }) => (
        <div className='formik-input-color' ref={colorPickerRef}>
          {label && <label className='formik-label'>{label}</label>}
          <div className='color-input'>
            <div
              className='color-preview'
              style={{ backgroundColor: selectedColor }}
              onClick={() => setColorPickerVisible(!colorPickerVisible)}
            ></div>
            <div className='input-container'>
              <input
                type='text'
                {...field}
                value={selectedColor}
                onChange={handleInputChange}
                onClick={() => setColorPickerVisible(!colorPickerVisible)}
                className='input-field' // Add this class for styling
              />
              {colorPickerVisible && (
                <div className='container'>
                  <div className='color-picker'>
                    <SketchPicker color={selectedColor} onChange={handleColorChange} />
                    <div className='row mt-1'>
                      <div className='col'>
                        <RobolButton className='w-100 mt-1' theme='dark' size='s' onClick={handleReset}>
                          Resetten
                        </RobolButton>
                      </div>
                      <div className='col'>
                        <RobolButton className='w-100 mt-1' theme='green' size='s' onClick={handleConfirmClick}>
                          Bevestigen
                        </RobolButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {postfix && <div className='postfix'>{postfix}</div>}
            </div>
          </div>
        </div>
      )}
    </Field>
  );
};

export default FormikInputColor;
