import React from "react";
import RobolButton from "../../components/RobolButton";
import ForwardingRequestPopup from "../../formik/components/ForwardingRequestPopup";
import { ForwardingStatus } from "../../helpers/Enums";
import moment from "moment";

export const EmailDetails = ({
  sendMessage,
  userMessage,
  setUserMessage,
  selectedConversation,
  handleKeyDown,
  forwardAccept,
  setForwardAccept,
}) => {
  if (
    !selectedConversation ||
    !selectedConversation.conversation_data ||
    selectedConversation.conversation_data.length === 0
  ) {
    return <div className="email-content empty">Gesprek nog niet gekozen</div>;
  }

  const isClosed = selectedConversation.conversation_closed;
  const isClosedDatetime = selectedConversation.conversation_closed_date;
  const formattedDatetime = moment(isClosedDatetime).format(
    "DD-MM-YYYY HH:mm:ss"
  );

  return (
    <>
      <div className="email-content-container">
        <div className="email-content">
          <div className="email-content__header">
            <div className="email-content__subject">
              #{selectedConversation.id} /{" "}
              {moment(selectedConversation.started_at).format("D-M-Y:h:s")}
            </div>
          </div>
          <div className="email-content__message">
            {selectedConversation.conversation_data.map((message, index) => (
              <div
                key={index}
                className={`message-bubble ${
                  message.role === "user" ? "user-message" : "bot-message"
                }`}
              >
                {message.role === "user" && (
                  <span className="userResponseText">{message.content}</span>
                )}
                {message.role !== "user" && (
                  <span className="responsText">{message.content}</span>
                )}
              </div>
            ))}
            {selectedConversation.forward ===
              ForwardingStatus.FORWARD_REQUESTED && (
              <ForwardingRequestPopup
                sessionId={selectedConversation.sessionId}
                forwarded_to={selectedConversation.forwarded_to}
                forwardAccept={forwardAccept}
                setForwardAccept={setForwardAccept}
              />
            )}
          </div>
          {isClosed && (
            <>
              <div className="container py-3">
                <div className="row justify-content-center">
                  <p>
                    Conversation has been closed at <b>{formattedDatetime}.</b>
                  </p>
                </div>
              </div>
            </>
          )}

          <form id="messenger" className="message-input-form">
            <div className="input-group inbox-general-input">
              <input
                type="text"
                className="form-control"
                name="msg"
                placeholder="Bericht versturen..."
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <div className="input-group-append">
                <RobolButton onClick={sendMessage} theme="green">
                  Verzend
                </RobolButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
