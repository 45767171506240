// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forwarding-request-modal .popup {
  background-color: var(--rbl-green);
  padding: 1rem;
  border-radius: 8px;
  width: 40%;
  z-index: 1000;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/formik/components/css/ForwardingRequestPopup.scss"],"names":[],"mappings":"AACI;EACI,kCAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,kBAAA;AAAR","sourcesContent":[".forwarding-request-modal {\n    .popup {\n        background-color: var(--rbl-green);\n        padding: 1rem;\n        border-radius: 8px;\n        width: 40%;\n        z-index: 1000;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
