import React from "react";
import "../../css/PrivateRoutes/IntelligenceCard.css";
import "../../css/PrivateRoutes/PromptsCard.css";
import "../../css/PrivateRoutes/WebScraperCard.css";
import { FormikSelect } from "../../formik/components/FormikSelect";
import { GptModels, TooltipContent } from "../../helpers/Enums";
import RobolButton from "../RobolButton";
import CardWrapper from "./CardWrapper";
import Tooltip from "../../helpers/Tooltip";

const ModelPowerCard = ({
  initialValues,
  setGptModelValues,
  currentManualStep,
  onSubmit,
}) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setGptModelValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const gptModelOptions = Object.keys(GptModels).map((key) => ({
    value: GptModels[key],
    label: key,
  }));

  const renderInputFields = () => {
    return Object.keys(initialValues)
      .filter((key) => key !== "isTouched" && key !== "isSaved")
      .map((key) => (
        <div key={key} className="row my-2">
          <div className="col-md-6">
            <FormikSelect
              name={key}
              label={key}
              placeholder={key}
              value={initialValues[key]}
              onChange={handleInputChange}
              options={gptModelOptions} // Pass the options array
            />
          </div>
        </div>
      ));
  };

  return (
    <>
      <div className="row">
        <div className="block-margin"></div>
        <div className="col pb-5">
          <CardWrapper className="mb-2" minHeight="50vh">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h5 className="intelligence-cards-title d-flex align-items-center">
                  {currentManualStep}
                  <div className="ml-2">
                    <Tooltip toolTip={TooltipContent.MODEL_POWER} />
                  </div>
                </h5>
              </div>
            </div>
            <hr className="black" />
            <div className="row justify-content-center">
              <div className="col-12 mb-5">
                {renderInputFields()}
                <div className="row">
                  <div className="col d-flex justify-content-start mt-1">
                    <RobolButton onClick={onSubmit} theme="dark">
                      Model opslaan
                    </RobolButton>
                  </div>
                </div>
              </div>
            </div>
          </CardWrapper>
        </div>
      </div>
    </>
  );
};

export default ModelPowerCard;
