import React, { useState } from "react";
import DynamicTable from "../DynamicTable";
import DocumentActionMenu from "./DocumentActionMenu";

const WebScrapeTable = ({ scrapedWebsite = [], onDelete }) => {
  const [webScrapedId, setWebScrapedId] = useState(null);

  const toggleMenu = (documentId) => {
    if (webScrapedId === documentId) {
      setWebScrapedId(null);
    } else {
      setWebScrapedId(documentId);
    }
  };

  const columns = [
    { Header: "Website", accessor: "url" },
    { Header: "Status", accessor: "status" },
    { Header: "Uploaddatum", accessor: "timestamp" },
    {
      Header: "Acties",
      accessor: "actions",
      Cell: ({ original }) => (
        <DocumentActionMenu
          isOpen={webScrapedId === original.id}
          toggleMenu={toggleMenu}
          documentId={original.id}
          onDelete={() => onDelete(original.id)}
        />
      ),
    },
  ];

  return (
    <div className="document-table">
      <DynamicTable
        onRowClick={(row) => {
          console.log("Row clicked", row);
        }}
        columns={columns}
        data={scrapedWebsite}
      />
      {scrapedWebsite.length === 0 && (
        <table className="table mb-3 purple-text-table">
          <tbody>
            <tr>
              <td colSpan={columns.length} className="text-center">
                Geen resultaten gevonden.
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WebScrapeTable;
