import { notify } from "../../formik/components/Toast";
import { ToastNotifications } from "../../helpers/Enums";

export const uploadToAPI = async (endpoint, token, formData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}${endpoint}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to upload to ${endpoint}`);
    }
    notify(ToastNotifications.UPLOADED);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error uploading to ${endpoint}:`, error);
    throw new Error(error.message);
  }
};

export const fetchFromAPI = async (endpoint, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}${endpoint}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch from ${endpoint}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching from ${endpoint}:`, error);
    throw new Error(error.message);
  }
};

export const patchToAPI = async (endpoint, token, body) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}${endpoint}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to patch to ${endpoint}`);
    }

    const data = await response.json();
    notify(ToastNotifications.CHANGES_SAVED);

    return data;
  } catch (error) {
    console.error(`Error patching to ${endpoint}:`, error);
    notify(ToastNotifications.SCRAPE_ERROR);
    throw new Error(error.message);
  }
};
export const postToAPI = async (endpoint, token, body) => {
  try {
    console.log(`Sending POST request to ${endpoint} with body:`, body);

    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}${endpoint}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Response not OK:', errorData);
      throw new Error(`Failed to post to ${endpoint}: ${errorData.message}`);
    }

    const data = await response.json();
    console.log('Response JSON:', data);

    notify(ToastNotifications.CHANGES_SAVED);

    return data;
  } catch (error) {
    console.error(`Error posting to ${endpoint}:`, error);
    throw new Error(error.message);
  }
};




export const deleteFromAPI = async (endpoint, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}${endpoint}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to delete from ${endpoint}`);
    }

    let data;
    try {
      data = await response.clone().json();
    } catch (e) {
      data = await response.text();
      console.warn(`Response from ${endpoint} is not valid JSON:`, data);
    }
    notify(ToastNotifications.DELETED);

    return data;
  } catch (error) {
    console.error(`Error deleting from ${endpoint}:`, error);
    throw new Error(error.message);
  }
};
