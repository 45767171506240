import React, { useState } from "react";
import {
  AddSquareIcon,
  ContextIcon,
  PencilIcon,
} from "../../assets/svgs/SvgIcons";
import "../../css/PrivateRoutes/IntelligenceCard.css";
import "../../css/PrivateRoutes/PromptsCard.css";
import { FormikTextArea } from "../../formik/components/FormikTextArea";
import RobolButton from "../RobolButton";
import CardWrapper from "./CardWrapper";
import CategoryModal from "./CategoryModal";
import PromptCardWrapper from "./PromptCardWrapper";

const PromptsCard = ({
  setCategoryValues,
  currentManualStep,
  saveCategory,
  initialValues,
  savePrompt,
  deleteCategory, 
  deletePrompt, 
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [inputValues, setInputValues] = useState({});

  const handleCreateCategory = (categoryName) => {
    if (!categoryName.trim()) {
      setError("Categorie kan niet leeg zijn.");
    } else {
      setError("");
      saveCategory(categoryName);
      setIsModalOpen(false);
    }
  };

  const handleInputChange = (categoryIndex, promptIndex, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [`${categoryIndex}-${promptIndex}`]: value,
    }));
  };

  // Sort categories: newest first, then move empty categories to the bottom
  const sortedCategories = [...(initialValues.categories || [])].sort(
    (a, b) => {
      if (a.prompts.length === 0 && b.prompts.length > 0) return 1;
      if (a.prompts.length > 0 && b.prompts.length === 0) return -1;
      return new Date(b.createdAt) - new Date(a.createdAt); // Assuming categories have a 'createdAt' field
    }
  );

  return (
    <>
      <div className="row">
        <div className="block-margin"></div>
        <div className="col pb-5">
          <CardWrapper className="mb-2" minHeight="50vh">
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center">
                <h5>{currentManualStep}</h5>
                {initialValues?.categories && (
                  <button
                    onClick={() => setIsModalOpen(true)}
                    style={{ background: "none", border: "none", padding: 0 }}
                  >
                    <AddSquareIcon width="100px" color="#1E3050" />
                  </button>
                )}
              </div>
            </div>
            <hr className="black" />
            {sortedCategories.length > 0 ? (
              sortedCategories.map((category, categoryIndex) => (
                <PromptCardWrapper
                  key={category.id}
                  deleteCategory={() => deleteCategory(category.id)}
                  title={category.name}
                >
                  <div className="col">
                    {category.prompts?.map((prompt, promptIndex) => (
                      <div className="container-fluid py-3" key={prompt.id}>
                        <div className="prompt-item d-flex justify-content-between align-items-center">
                          <span>
                            <b>{`Prompt ${promptIndex + 1}: `}</b>
                            {prompt.text}
                          </span>
                          <button
                            onClick={() => deletePrompt(prompt.id)}
                            className="prompt-action-button"
                          >
                            <PencilIcon
                              width={16}
                              height={16}
                              color="#1E3050"
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div key={categoryIndex} className="category-item">
                    <FormikTextArea
                      name={`categories[${categoryIndex}].prompts[${
                        category.prompts?.length || 0
                      }]`}
                      placeholder="Voeg jouw prompt toe"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          category.prompts?.length || 0,
                          e.target.value
                        )
                      }
                      value={
                        inputValues[
                          `${categoryIndex}-${category.prompts?.length || 0}`
                        ] || ""
                      }
                    />
                  </div>

                  <div className="d-flex justify-content-end container-fluid">
                    <RobolButton
                      onClick={() => {
                        const inputValue =
                          inputValues[
                            `${categoryIndex}-${category.prompts?.length || 0}`
                          ];
                        savePrompt(category.id, inputValue); // Pass the actual input value
                      }}
                      theme="dark"
                      size="m"
                      disabled={
                        !inputValues[
                          `${categoryIndex}-${category.prompts?.length || 0}`
                        ]?.trim()
                      }
                    >
                      Opslaan
                    </RobolButton>
                  </div>
                </PromptCardWrapper>
              ))
            ) : (
              <div className="container mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="prompts-card text-center mt-3">
                      <ContextIcon width={35} color="#1E3050" />
                      <p className="my-3">
                        Je hebt nog geen categorieën gecreëerd. Klik op de knop
                        hieronder om jouw eerste categorie aan te maken en start
                        met het toevoegen van prompts.
                      </p>
                      <RobolButton
                        onClick={() => setIsModalOpen(true)}
                        theme="dark"
                        size="m"
                      >
                        Creëer categorie
                      </RobolButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </CardWrapper>
        </div>
      </div>
      <CategoryModal
        error={error}
        isOpen={isModalOpen}
        onCreate={handleCreateCategory}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default PromptsCard;
