// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-wrapper {
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    padding: 20px;
    width: auto;
    height: auto;
    overflow: hidden;
    border: 0.3px solid white;
}

.steps-wrapper:hover {
    cursor: pointer;
    background: #FFFFFF;
    color: #1E3050;
    border: 0.3px solid #1E3050;
    /* Fix the border color */
}

.steps-wrapper.active {
    color: white;
    border: 0.3px solid #1E3050;
    /* Fix the border color */
    background-color: #1E3050;
}




`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/StepCardsWrapper.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,6CAA6C;IAC7C,aAAa;IACb,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,2BAA2B;IAC3B,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,yBAAyB;IACzB,yBAAyB;AAC7B","sourcesContent":[".steps-wrapper {\n    background: #FFFFFF;\n    border-radius: 8px;\n    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);\n    padding: 20px;\n    width: auto;\n    height: auto;\n    overflow: hidden;\n    border: 0.3px solid white;\n}\n\n.steps-wrapper:hover {\n    cursor: pointer;\n    background: #FFFFFF;\n    color: #1E3050;\n    border: 0.3px solid #1E3050;\n    /* Fix the border color */\n}\n\n.steps-wrapper.active {\n    color: white;\n    border: 0.3px solid #1E3050;\n    /* Fix the border color */\n    background-color: #1E3050;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
