// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width-line {
  width: 100%;
  border-top: 1px solid #ccc;
  /* Change the color as needed */
}



/*Step 3*/
/* ... your existing styles ... */

.code-box {
  background-color: var(--rbl-blue) !important;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.code-content {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  white-space: pre-wrap;
  min-height: 120px;
}

.copy-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.copy-button:hover {
  background-color: #0056b3;
}


.terminal {
  background-color: black !important;
}


.code-content.terminal {
  background-color: #0e0e0e !important;
  color: #00f53d;
  font-family: monospace;
  padding: 20px;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-x: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.code-content.terminal pre {
  margin: 0;
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/ActivationCard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,0BAA0B;EAC1B,+BAA+B;AACjC;;;;AAIA,SAAS;AACT,iCAAiC;;AAEjC;EACE,4CAA4C;;EAE5C,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,kBAAkB;EAClB,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA;EACE,kCAAkC;AACpC;;;AAGA;EACE,oCAAoC;EACpC,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;EAChB,0CAA0C;AAC5C;;AAEA;EACE,SAAS;EACT,qBAAqB;AACvB","sourcesContent":[".full-width-line {\n  width: 100%;\n  border-top: 1px solid #ccc;\n  /* Change the color as needed */\n}\n\n\n\n/*Step 3*/\n/* ... your existing styles ... */\n\n.code-box {\n  background-color: var(--rbl-blue) !important;\n\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  border-radius: 8px;\n  padding: 16px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.code-content {\n  border: 1px solid #e0e0e0;\n  border-radius: 4px;\n  padding: 8px;\n  margin-bottom: 8px;\n  white-space: pre-wrap;\n  min-height: 120px;\n}\n\n.copy-button {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  padding: 8px 12px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.copy-button:disabled {\n  background-color: #e0e0e0;\n  cursor: not-allowed;\n}\n\n.copy-button:hover {\n  background-color: #0056b3;\n}\n\n\n.terminal {\n  background-color: black !important;\n}\n\n\n.code-content.terminal {\n  background-color: #0e0e0e !important;\n  color: #00f53d;\n  font-family: monospace;\n  padding: 20px;\n  border-radius: 5px;\n  white-space: pre-wrap;\n  overflow-x: auto;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.code-content.terminal pre {\n  margin: 0;\n  white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
