import React, { useEffect } from "react";
import {
  PencilSquare,
  ThreeDots,
  ThreeDotsVertical,
  Trash,
} from "react-bootstrap-icons";
import "../../css/PrivateRoutes/DocumentActionMenu.css";

const DocumentActionMenu = ({ isOpen, toggleMenu, documentId, onDelete }) => {
  const closeMenu = (event) => {
    if (!event.target.closest(".action-menu-container")) {
      toggleMenu(null);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", closeMenu);
    }
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [isOpen]);

  return (
    <div className="action-menu-container">
      <ThreeDotsVertical
        onClick={() => toggleMenu(documentId)}
        className="action-menu-icon"
      />
      <div
        className={`action-menu-dropdown ${
          isOpen ? "action-menu-dropdown-visible" : ""
        }`}
      >
        {/* <button onClick={onEdit}>
          <PencilSquare className="icon" /> Edit
        </button> */}
        <button onClick={onDelete}>
          <Trash className="icon" /> Delete
        </button>
      </div>
    </div>
  );
};

export default DocumentActionMenu;
