// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  @media (max-width: 768px) {
    .block-margin{
      display: none;
    }
  
  }
  
  .block-margin {
    width: 26%;
  }
  
  .category-item {
    padding: 1rem;
  }
  
  .restriction-item {
    padding: 1rem;
    background: var(--rbl-green-l);
    box-shadow: 1px 1px 1px 1px rgba(204, 198, 198, 0);
    border-radius: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/RestrictionsCard.css"],"names":[],"mappings":";;EAEE;IACE;MACE,aAAa;IACf;;EAEF;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,kDAAkD;IAClD,kBAAkB;EACpB","sourcesContent":["\n  \n  @media (max-width: 768px) {\n    .block-margin{\n      display: none;\n    }\n  \n  }\n  \n  .block-margin {\n    width: 26%;\n  }\n  \n  .category-item {\n    padding: 1rem;\n  }\n  \n  .restriction-item {\n    padding: 1rem;\n    background: var(--rbl-green-l);\n    box-shadow: 1px 1px 1px 1px rgba(204, 198, 198, 0);\n    border-radius: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
