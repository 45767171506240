import React from "react";
import "../../css/PrivateRoutes/CardWrapper.css";

const CardWrapper = ({ children, minHeight, background }) => {
  return (
    <div
      style={{ minHeight: minHeight || "", background: background || "White" }}
      className="card-wrapper"
    >
      {children}
    </div>
  );
};

export default CardWrapper;

