import { Form, Formik, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { GeneralLoader } from "../../components/GeneralLoader";
import HeaderCards from "../../components/HeaderCards";
import FormWrapper from "../../components/PrivateRoutes/FormWrapper";
import RobolButton from "../../components/RobolButton";
import "../../css/PrivateRoutes/ProfileImageUpload.css";
import { auth } from "../../firebase";
import { createChat } from "../../services/api/ChatService";
import FocusError from "./FocusError";
import { FormikInput } from "./FormikInput";

export const NewBotForm = ({
  fields,
  onSubmit,
  validationSchema,
  initialValues,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues || { employeeName: "" },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      setIsLoading(true);
      const token = await auth.currentUser.getIdToken(true);

      try {
        await createChat(token, values.employeeName);

        onSubmit(values, actions);

      } catch (error) {
        console.error("Failed to create chat", error);
      } finally {
        navigate("/dashboard"); 

        setIsLoading(false);
      }
    },
  });

  if (isLoading) {
    return <GeneralLoader />;
  }

  const renderFormStep = ({ errors, touched }) => {
    return (
      <FormikProvider value={formik}>
        <FormWrapper>
          <FocusError />
          <Form className="p-1">
            <HeaderCards title="Initiële creatie van de bot" />
            <div>
              <div className="mt-3">
                <FormikInput
                  name="employeeName"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="mt-4">
              <RobolButton
                onClick={() => formik.submitForm()}
                theme="dark"
                size="m"
                type="submit"
              >
                Creëer je chatbot
              </RobolButton>
            </div>
          </Form>
        </FormWrapper>
      </FormikProvider>
    );
  };

  return (
    <>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values, actions);
        }}
      >
        {({ errors, touched }) => (
          <>
            <div className="row">
              <div className="col-md-6 col-8">
                <div className="row mb-3">
                  <div className="col">
                    <div className="user-card mb-3">
                      {renderFormStep({ errors, touched })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

export default NewBotForm;
