import React from "react";
import { InboxSectionTitle } from "../../components/InboxSectionTitle";
import {
  BellIcon,
  CheckMarkEnvelopIcon,
  ClosedEnvelopIcon,
  InboxIcon,
  MessageEnvelopIcon,
  OpenEnvelopIcon,
} from "../../assets/svgs/SvgIcons";
import { IconColor } from "../../assets/svgs/SvgIconColors";
import {
  ConversationClosed,
  ForwardingStatus,
  MessagesStatus,
  UserRoles,
} from "../../helpers/Enums";

export const Sidebar = ({
  conversations,
  currentSection,
  handleSectionClick,
  companyUsers,
  setSelectedEmailId,
  selectedEmail,
  setSelectedEmail,
  role,
}) => {
  const handleUserClick = (user) => {
    setSelectedEmail(user.email);
    setSelectedEmailId(user.user_id); // Set the selected email ID to the user's ID
    handleSectionClick("user"); // Trigger the action for selecting a user
  };

  console.log("rolerolerole", role);
  return (
    <div id="sidebar">
      <div className="p-3 header-content d-flex justify-content-between">
        <h5 className="white">Messagehub</h5>
      </div>
      <ul className="sidebar__inboxes">
        <InboxSectionTitle sectionTitle={"Berichten"} />
        <li
          onClick={() => handleSectionClick("inbox")}
          className={currentSection === "inbox" ? "active" : ""}
        >
          <span>
            <InboxIcon color={IconColor.SECONDARY} width="15px" />
            <span className="item-title">Alles</span>
            <span className="item-count">{conversations?.length}</span>
          </span>
        </li>
        <li
          onClick={() => handleSectionClick("forward")}
          className={currentSection === "forward" ? "active" : ""}
        >
          <span>
            <BellIcon color={IconColor.SECONDARY} width="15px" />
            <span className="item-title">Verzoeken</span>
            <span className="item-count">
              {
                conversations?.filter(
                  (conversation) =>
                    conversation?.forward === ForwardingStatus.FORWARD_REQUESTED
                ).length
              }
            </span>
          </span>
        </li>
        <li
          onClick={() => handleSectionClick("unread")}
          className={currentSection === "unread" ? "active" : ""}
        >
          <span>
            <ClosedEnvelopIcon color={IconColor.SECONDARY} width="15px" />
            <span className="item-title">Ongelezen</span>
            <span className="item-count">
              {
                conversations?.filter(
                  (conversation) =>
                    conversation.is_read === MessagesStatus.IS_NOT_READ
                ).length
              }
            </span>
          </span>
        </li>
        <li
          onClick={() => handleSectionClick("read")}
          className={currentSection === "read" ? "active" : ""}
        >
          <span>
            <OpenEnvelopIcon color={IconColor.SECONDARY} width="15px" />
            <span className="item-title">Gelezen</span>
            <span className="item-count">
              {
                conversations?.filter(
                  (conversation) =>
                    conversation.is_read === MessagesStatus.IS_READ
                ).length
              }
            </span>
          </span>
        </li>

        <li
          onClick={() => handleSectionClick("closed")}
          className={currentSection === "closed" ? "active" : ""}
        >
          <span>
            <CheckMarkEnvelopIcon color={IconColor.SECONDARY} width="15px" />
            <span className="item-title">Gesloten</span>
            <span className="item-count">
              {
                conversations?.filter(
                  (conversation) =>
                    conversation.conversation_closed ===
                    ConversationClosed.IS_CLOSED
                ).length
              }
            </span>
          </span>
        </li>
      </ul>
      {role === UserRoles.ADMIN && (
        <ul className="sidebar__inboxes">
          <InboxSectionTitle sectionTitle={"Gebruikers"} />
          {companyUsers.length === 0 ? (
            <div className="container">
              <div className="py-3 pl-2 row ">
                <span className="no-users">Geen gebruikers beschikbaar</span>
              </div>
            </div>
          ) : (
            companyUsers.map((user) => (
              <li
                key={user.user_id}
                onClick={() => handleUserClick(user)}
                className={selectedEmail === user.email ? "active" : ""}
              >
                <img
                  src={`data:image/png;base64,${user.picture}`}
                  alt={`${user.first_name} ${user.last_name}`}
                  className="user-picture"
                />
                <span className="item-title">{`${user.first_name} ${user.last_name}`}</span>
                <span className="item-count">
                  {
                    conversations?.filter(
                      (conversation) => conversation.forwarded_to === user.email
                    ).length
                  }
                </span>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};
