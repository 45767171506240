import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "nl", // Set the default language to Dutch
    fallbackLng: "nl",
    backend: {
      loadPath: "/{{lng}}.json",
    },
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,  // Set this to false to avoid Suspense. You'll need to handle loading states manually.
    },
    returnObjects: false, // Make sure this is not set to true
  });

export default i18n;
