import { signOut, setPersistence, onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { browserSessionPersistence } from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        // Set persistence only if the user is logged in
        setPersistence(auth, browserSessionPersistence);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    setCurrentUser: (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        // Only perform sign-out if the user is explicitly logging out
        signOut(auth)
          .then(() => {
            // Reset the current user state after successful sign-out
            setCurrentUser(null);
          })
          .catch((error) => {
            console.error("Error during sign out:", error);
          });
      }
    },
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
