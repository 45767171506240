/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { FormikToggle } from "../../formik/components/FormikToggle";
import { currentTime } from "../../helpers/helpers";
import RobolButton from "../../components/RobolButton";
import "../../css/PrivateRoutes/UserOverviewHeader.css";

import defaultEmployeeIcon from "../../assets/defaultEmployee.png"; // Import the icon
import { useNavigate } from "react-router-dom";

export const UserOverviewHeader = ({
  toggleSubmit,
  toggleLabel,
  first_name,
  last_name,
  picture,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="row align-items-center py-2 mb-1">
      {/* Left side */}
      <div className="col-6 col-md-8 d-flex align-items-center">
        <img
          src={
            picture ? `data:image/png;base64,${picture}` : defaultEmployeeIcon
          }
          alt="Avatar"
          className="employeeIcon mr-2"
        />
        <div>
          <b className="user-greeting">
            Welkom {first_name} {last_name}
          </b>
          <div className="d-flex align-items-center">
            <FormikToggle
              name="available"
              onToggle={toggleSubmit}
              label={toggleLabel}
            />
            <span className="ml-2">Beschikbaar</span>
          </div>
        </div>
      </div>
      {/* Right side (visible on larger screens) */}
      {!isMobile && (
        <div className="col-md-4 d-none d-md-flex justify-content-end align-items-center">
          <div className="current-time mr-2">{currentTime}</div>
          {/* <a href="../user/messageshub">
            <RobolButton theme="green">Messagehub</RobolButton>
          </a> */}
        </div>
      )}
    </div>
  );
};
