import React, { useEffect } from "react";
import "../../css/PrivateRoutes/ChatbotSkeleton.css";
import {
  CrossIcon,
  MinimizeIcon,
  RobolLogo,
  SendIcon,
} from "../../assets/svgs/SvgIcons";

import defaultAvatarChatbot from "../../assets/defaultAvatarChatbot.png";
import defaultAvatarChatbox from "../../assets/whitebluelogo.png";

const ChatbotSkeleton = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  buttonColor,
  avatarChatBot,
  avatarChatBox,
}) => {
  const chatBotAvatar = avatarChatBot ? avatarChatBot : defaultAvatarChatbot;
  const chatBoxAvatar = avatarChatBox ? avatarChatBox : defaultAvatarChatbox;

  useEffect(() => {
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty("--secondary-color", secondaryColor);
    document.documentElement.style.setProperty("--tertiary-color", tertiaryColor);
    document.documentElement.style.setProperty("--color-button", buttonColor);
  }, [primaryColor, secondaryColor, tertiaryColor, buttonColor]);

  const messages = [
    {
      sender: "bot",
      message: "Hallo! Hoe kan ik je vandaag helpen?",
    },
    {
      sender: "user",
      message: "Waarom zijn aangepaste kleuren op mijn website belangrijk?",
    },
    {
      sender: "bot",
      message:
        "Aangepaste kleuren spelen een cruciale rol in webontwikkeling. Ze helpen bij het creëren van een unieke en gedenkwaardige merkidentiteit voor je bedrijf.",
    },
    {
      sender: "user",
      message: "Hoe beïnvloeden kleuren de perceptie van klanten?",
    },
    {
      sender: "bot",
      message:
        "Kleuren roepen emoties en gevoelens op. Zo kan blauw vertrouwen en professionaliteit overbrengen, terwijl rood opwinding en urgentie kan symboliseren.",
    },
    {
      sender: "user",
      message:
        "Kun je een voorbeeld geven van hoe kleurkeuze een bedrijf heeft beïnvloed?",
    },
    {
      sender: "bot",
      message:
        "Natuurlijk! Een bekende fastfoodketen gebruikte rood en geel in hun branding, wat een gevoel van urgentie en vreugde creëerde. Dit leidde tot meer verkoop en tevreden klanten.",
    },
    {
      sender: "user",
      message:
        "Dus kleuren kunnen echt het verschil maken in het succes van mijn bedrijf?",
    },
    {
      sender: "bot",
      message:
        "Absoluut! Het kiezen van de juiste kleuren kan je onderscheiden, een sterk merk creëren en een connectie maken met je doelgroep.",
    },
    {
      sender: "user",
      message: "Bedankt voor de waardevolle informatie!",
    },
    {
      sender: "bot",
      message:
        "Graag gedaan! Als je meer vragen hebt of hulp nodig hebt met iets anders, aarzel dan niet om te vragen.",
    },
  ];

  return (
    <div className="chatbot-container robolCss">
      <div className="showBotSubject">
        <div className="Layout Layout-open Layout-expand Layout-right">
          <div className="Messenger_messenger">
            <div className="Messenger_header">
              <div className="header-content">
                <div className="header-left">
                  <div className="logoRobol">
                    {chatBoxAvatar ? (
                      <img
                        src={chatBoxAvatar}
                        width="35"
                        height="35"
                        alt={"companyLogo"}
                      />
                    ) : (
                      <RobolLogo />
                    )}
                  </div>

                  <span className="employee-name">
                    Robol
                    <br />
                    <span className="department">Afdeling design</span>
                  </span>
                </div>

                <span className={"chat_minimize_icon"}>
                  <MinimizeIcon fill="black" width="20px" />
                </span>

                <span className="chat_close_icon">
                  <CrossIcon fill="black" width="20px" />
                </span>
              </div>
            </div>
            <div className="Messenger_content">
              <div className="Messages">
                <div className="Messages_list">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`msg ${msg.sender === "user" ? "user" : ""}`}
                    >
                      <span className="avtr">
                        {msg.sender === "user" ? (
                          <></>
                        ) : (
                          <div className="avatar-container">
                            <img
                              src={chatBotAvatar}
                              alt="defaultAvatarChatbot"
                            />
                          </div>
                        )}
                      </span>

                      <span
                        className={`${
                          msg.sender === "user"
                            ? "userResponseText"
                            : "responsText"
                        }`}
                      >
                        {msg.message}
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <form id="messenger">
                <div className="Input Input-blank">
                  <input
                    name="msg"
                    className="Input_field"
                    placeholder="Bericht versturen..."
                  />
                  <button
                    type="submit"
                    disabled
                    className="Input_button Input_button-send"
                  >
                    <div className="Icon">
                      <SendIcon />
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatbotSkeleton;
