import { Form } from "formik";
import React, { useState, useEffect } from "react";
import "../../css/PrivateRoutes/CategoryModal.css";
import { FormikInput } from "../../formik/components/FormikInput";
import RobolButton from "../RobolButton";
import { FormikField } from "../../formik/components/FormikField";

const CategoryModal = ({
  isOpen,
  onCreate,
  onCancel,
  saveCategory,
  formikField,
  error,
}) => {
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    // Reset the input field when the modal opens
    if (isOpen) {
      setCategoryName("");
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="category_modal">
      <div className="category_modalcontent ">
        <div className="modal-header">
          <h5 className="modal-title">Geef jouw categorie een naam</h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={onCancel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body ">
          <p className="mb-2">Vul hieronder de naam van jouw categorie in.</p>
          <Form>
            <FormikField
              name={formikField}
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Pizza bestellen"
              className="form-control"
            />
            {error && <div className="text-danger">{error}</div>}{" "}
          </Form>
        </div>
        <div className="modal-footer">
          <RobolButton theme="invisible" onClick={onCancel}>
            Annuleer
          </RobolButton>
          <RobolButton
            theme="dark"
            onClick={() => {
              onCreate(categoryName);
            }}
          >
            Creëer categorie
          </RobolButton>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
