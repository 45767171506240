import React, { createContext, useContext, useState } from "react";

const ConversationsContext = createContext();

export const useConversations = () => useContext(ConversationsContext);

export const ConversationsProvider = ({ children }) => {
  const [conversations, setConversations] = useState([]);

  const updateConversationReadStatus = (conversationId, isRead) => {
    setConversations((currentConversations) =>
      currentConversations.map((convo) =>
        convo.id === conversationId ? { ...convo, is_read: isRead } : convo
      )
    );
  };

  return (
    <ConversationsContext.Provider
      value={{ conversations, updateConversationReadStatus }}
    >
      {children}
    </ConversationsContext.Provider>
  );
};
