import React from 'react';
import Objects3Loader from '../components/Objects3Loader';

const LogoLoader = ({ size, src }) => (
  <Objects3Loader
    size={size}
    src={src}
  />
);

export default LogoLoader;
