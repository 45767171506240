import { Field, useField } from "formik";
import React from "react";
import "./css/FormikInput.css";
export const FormikField = ({ placeholder, name, ...props }) => {
  const [field, meta] = useField(name);
  const isValid = meta.touched && !meta.error;
  return (
    <Field name={name}>
      {({ field, form: { touched, errors } }) => (
        <div className="fs-form-group fade-in-image">
          <input
            className={`formik-input form-control ${
              meta.touched && meta.error ? "is-invalid" : ""
            }`}
            {...field}
            {...props}
            placeholder={placeholder}
          />
          {touched[field.name] && errors[field.name] && (
            <div className="text-danger">{errors[field.name]}</div>
          )}
        </div>
      )}
    </Field>
  );
};