import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/PrivateRoutes/AdminDashboard.css";
import EditBotCard from "../../components/PrivateRoutes/EditBotCard";
import EditEmployeeCard from "../../components/PrivateRoutes/EditEmployeeCard";
import defaultEmployeePic from "../../assets/defaultEmployee.png";
import NewEmployeeBotCard from "../../components/PrivateRoutes/NewEmployeeBotCard";
import FormAddBot from "../../components/PrivateRoutes/FormAddBot";
import FormAddEmployee from "../../components/PrivateRoutes/FormAddEmployee";
import { useNavigate } from "react-router-dom";
import ClickThroughText from "../../components/ClickTroughText";

const AdminDashboard = ({ companyUsers = [], bots = [] }) => {
  const [showAddFormBot, setShowAddFormBot] = useState(false);
  const [showAddFormEmployee, setShowAddFormEmployee] = useState(false);
  const navigate = useNavigate();

  const handleAddButtonClick = () => {
    navigate("../admin/bot/add");
  };

  const handleAddEmployeeClick = () => {
    navigate("../admin/employee/add");
  };

  const calculateSlidesToShow = (items) => {
    const maxSlides = 3;
    return items.length > maxSlides ? maxSlides : items.length;
  };

  const dummyEmployees = [
    {
      gradient: { start: "#003366", end: "#4CA1AF" },
      profilePic: defaultEmployeePic,
      functionTitle: "Support Specialist",
      employeeDepartment: "Customer Relations",
    },
    {
      gradient: { start: "#2F80ED", end: "#56CCF2" },
      profilePic: defaultEmployeePic,
      functionTitle: "Product Manager",
      employeeDepartment: "Product Development",
    },
    {
      gradient: { start: "#0F2027", end: "#003366", middle: "#2C5364" },
      profilePic: defaultEmployeePic,
      functionTitle: "Developer",
      employeeDepartment: "Engineering",
    },
  ];

  const filteredCompanyUsers = companyUsers.map((user, index) => {
    const dummyData = dummyEmployees[index % dummyEmployees.length];
    return {
      ...user,
      gradient: dummyData.gradient,
      profilePic: `data:image/jpeg;base64,${user.picture}`,
      functionTitle: dummyData.functionTitle,
    };
  });

  const gradients = [
    { start: "#4ca1af", end: "#c4e0e5" },
    { start: "#6a11cb", end: "#2575fc" },
    { start: "#3a1c71", end: "#d76d77", middle: "#ffaf7b" },
    { start: "#7b4397", end: "#dc2430" },
    { start: "#302b63", end: "#24243e" },
    { start: "#654ea3", end: "#eaafc8" },
  ];

  const filteredBots = bots.map((bot, index) => {
    const gradient = gradients[index % gradients.length];
    return {
      ...bot,
      gradient,
    };
  });

  const botsSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: calculateSlidesToShow(filteredBots),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: calculateSlidesToShow(filteredBots),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: calculateSlidesToShow(filteredBots),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const empSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: calculateSlidesToShow(filteredCompanyUsers),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: calculateSlidesToShow(filteredCompanyUsers),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: calculateSlidesToShow(filteredCompanyUsers),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="carousel-container">
        {filteredBots.length > 0 && (
          <ClickThroughText
            title="GPT-bots"
            firstText="Andere GPT toevoegen?"
            clickText="Klik hier."
            navigateTo="../admin/bot/add"
          />
        )}
        <Slider {...botsSettings}>
          {filteredBots.length === 0 && (
            <NewEmployeeBotCard
              handleAddButtonClick={handleAddButtonClick}
              type="bot"
            />
          )}
          {filteredBots.map((bot, index) => (
            <div key={index}>
              <EditBotCard
                botName={bot.employeeName || "Unknown"}
                isActive={bot.on_off === 1 ? true : false}
                lastUsed={bot.created_at}
                gradient={bot.gradient}
                chatbotID={bot?.chatbotID}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="mt-5 carousel-container">
        {filteredCompanyUsers.length > 0 && (
          <ClickThroughText
            title="Medewerkers"
            firstText="Andere medewerker toevoegen?"
            clickText="Klik hier."
            navigateTo="../admin/employee/add"
          />
        )}
        <Slider {...empSettings}>
          {filteredCompanyUsers.length === 0 && (
            <NewEmployeeBotCard
              handleAddButtonClick={handleAddEmployeeClick}
              type="employee"
            />
          )}
          {filteredCompanyUsers.map((emp, index) => (
            <div key={index}>
              <EditEmployeeCard
                employeeName={`${emp.first_name} ${emp.last_name}`}
                role={emp.role}
                isActive={emp.is_active === 1 ? "Actief" : "Niet actief"}
                available={emp.available === 1 ? true : false}
                lastUsed={emp?.lastUsed || "Niet bekend"}
                gradient={emp.gradient}
                profilePic={emp?.picture}
                functionTitle={emp.functionTitle}
                employeeID={emp.user_id}
              />
            </div>
          ))}
        </Slider>
      </div>
      {showAddFormBot && (
        <FormAddBot onClose={() => setShowAddFormBot(false)} />
      )}
      {showAddFormEmployee && (
        <FormAddEmployee onClose={() => setShowAddFormEmployee(false)} />
      )}
    </>
  );
};

export default AdminDashboard;
