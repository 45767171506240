import React from "react";
import "../../css/PrivateRoutes/UserOverviewCardWrapper.css";

const UserOverviewCardWrapper = ({ children, minHeight, background }) => {
  return (
    <div
      style={{ minHeight: minHeight || "", background: background || "White" }}
      className="user-overview-card-wrapper"
    >
      {children}
    </div>
  );
};

export default UserOverviewCardWrapper;
