import React, { useState, useEffect } from "react";
import DynamicTable from "../DynamicTable";
import DocumentActionMenu from "./DocumentActionMenu";

const DocumentTable = ({ documents = [], chatbotID, onDelete }) => {
  const [openDocumentId, setOpenDocumentId] = useState(null);
  const [documentsList, setDocumentsList] = useState([]);

  useEffect(() => {
    setDocumentsList(documents);
  }, [documents]);

  const toggleMenu = (documentId) => {
    console.log(`Toggle menu called for documentId: ${documentId}`);
    if (openDocumentId === documentId) {
      setOpenDocumentId(null);
    } else {
      setOpenDocumentId(documentId);
    }
  };

  const columns = [
    { Header: "Bestandsnaam", accessor: "name", checkIcon: true },
    { Header: "Uploaddatum", accessor: "timestamp" },
    {
      Header: "Acties",
      accessor: "actions",
      Cell: ({ original }) => (
        <DocumentActionMenu
          isOpen={openDocumentId === original.id}
          toggleMenu={toggleMenu}
          documentId={original.id}
          onDelete={() => onDelete(original.id)}
        />
      ),
    },
  ];

  return (
    <div className="document-table">
      <DynamicTable
        onRowClick={(row) => {
          console.log("Row clicked", row);
        }}
        columns={columns}
        data={documentsList}
      />
      {documentsList.length === 0 && (
        <table className="table mb-3 purple-text-table">
          <tbody>
            <tr>
              <td colSpan={columns.length} className="text-center">
                Geen documenten gevonden.
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DocumentTable;
