import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { blogs } from "./Blog";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  // ... you can add more platforms if you want
} from "react-share";

const BlogComponent = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();

  const currentIndex = blogs.findIndex((blog) => blog.blogId === blogId);

  let blog = blogs[currentIndex];

  if (!blog) {
    blog = blogs[0];
  }
  const navigateToNextBlog = () => {
    const nextIndex = (currentIndex + 1) % blogs.length; // Loop back to the first blog if it's the last one
    navigate(`/blog/${blogs[nextIndex].blogId}`);
  };

  const shareUrl = window.location?.href;
  console.log("window", window.location?.href);
  const title = blog?.first_headline;
  const description = blog?.first_headline_text; // or any suitable description from the blog
  const imageUrl = blog?.imageurl;

  const currentUrl = typeof window !== "undefined" ? window.location.href : ""; // Check of 'window' bestaat om server-side rendering problemen te voorkomen.

  console.log(currentUrl);
  return (
    <div>
      <Helmet>
        <title>
          {blog.first_headline
            ? blog.first_headline
            : "AI Chatbots in Nederland: Kansen, Uitdagingen en Vooruitzichten"}
        </title>
        <link rel="canonical" href={currentUrl} />
        <meta
          name="description"
          content="Duik in de wereld van zakelijke A.I. chatbots met onze speciale Kennisbank. Ontdek hoe MKB's kunnen profiteren van kunstmatige intelligentie, de werking van chatbots, en hun zakelijke toepassingen. Maak complexe AI-termen eenvoudig met onze gids."
        />
        <meta
          name="keywords"
          content="Hoe werken AI chatbots in Nederland, Voordelen van AI chatbots voor Nederlandse bedrijven, Zakelijke toepassingen van chatbots in Nederland, Case studies over AI chatbot succes in Nederland, Recente trends in chatbot technologie in Nederland, Zakelijke chatbot strategieën voor MKB's, Tips voor het kiezen van een AI chatbot voor Nederlandse bedrijven, Vergelijking van populaire chatbot tools in Nederland, Handleiding voor het implementeren van een AI chatbot in MKB, Best practices voor chatbot klantenservice in Nederland"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={shareUrl} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <div className="container margin__custom mt-5">
        <div className="row">
          <div className="col-md-8">
            <div className="card mb-3">
              <img src={blog.imageurl} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">{title}</h5>
                {blog?.first_headline_text && (
                  <p className="card-text">{blog.first_headline_text}</p>
                )}

                <h5 className="card-title">{blog?.second_headline || ""}</h5>
                {blog?.second_headline_text && (
                  <p className="card-text">{blog.second_headline_text}</p>
                )}

                <h5 className="card-title">{blog?.third_headline || ""}</h5>
                {blog?.third_headline_text && (
                  <p className="card-text">{blog.third_headline_text}</p>
                )}

                <h5 className="card-title">{blog?.fourth_headline || ""}</h5>
                {blog?.fourth_headline_text && (
                  <p className="card-text">{blog.fourth_headline_text}</p>
                )}

                <h5 className="card-title">{blog?.fifth_headline || ""}</h5>
                {blog?.fifth_headline_text && (
                  <p className="card-text">{blog.fifth_headline_text}</p>
                )}

                <h5 className="card-title">{blog?.sixth_headline || ""}</h5>
                {blog?.sixth_headline_text && (
                  <p className="card-text">{blog.sixth_headline_text}</p>
                )}

                <p className="card-text">
                  <small className="text-muted">
                    Geschreven door: {blog?.autor || ""} /
                  </small>
                  <small className="text-muted">
                    {" "}
                    Laatst geupdated: {blog?.updated || ""}
                  </small>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <h6>{blog.side_firstcard_title}</h6>
                <p>{blog.side_firstcard_text}</p>
                <hr />
                <Link
                  to="https://calendly.com/robol-ai"
                  className="w-100 btn custom-btn-primary mr-md-3 mt-md-0 mb-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {blog.side_firstcard_button_1}
                </Link>
                <button
                  type="button"
                  className="w-100 btn custom-btn-secondary"
                  onClick={navigateToNextBlog}
                >
                  {blog.side_firstcard_button_2}
                </button>
              </div>
            </div>
            <br />
            <div className="card">
              <div className="card-body">
                <h5>{blog.side_second_card_title}</h5>
                <hr />
                <div className="ratio ratio-16x9">
                  <iframe
                    width={"100%"}
                    src={blog.side_second_card_link_video}
                    title="YouTube video"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="share-buttons mt-5">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="share-button mr-1"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="share-button mr-1"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={shareUrl}
            title={title}
            windowWidth={750}
            windowHeight={600}
            className="share-button"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  );
};

export default BlogComponent;
