import React from "react";
import Tooltip from "../helpers/Tooltip";
import "../css/HeaderCards.css";

const HeaderCards = ({ title, tooltipContent }) => (
  <>
    <div className="row">
      <div className="col d-flex align-items-center">
        <h5 className="intelligence-cards-title d-flex align-items-center">
          {title}
          {tooltipContent && (
            <div className="ml-2">
              <Tooltip toolTip={tooltipContent} />
            </div>
          )}
        </h5>
      </div>
    </div>
    <hr className="black" />
  </>
);

export default HeaderCards;
