import React, { useState } from "react";
import * as Yup from "yup";
import "../../css/PrivateRoutes/AddEmployee.css";
import { roleSelectbox } from "../../helpers/jsonData";
import NewUserForm from "../../formik/components/NewUserForm";

const AddEmployee = () => {
  const [activeSection, setActiveSection] = useState("personalDetails");

  const toggleSwitches = [{ name: "available", label: "Beschikbaar" }];

  const validationSchema = Yup.object().shape({
    // firstName: Yup.string().required("Voornaam verplicht"),
  });

  const fieldConfigurations = [
    {
      name: "role",
      type: "select",
      label: "Autorisatie",
      selectOptions: roleSelectbox,
    },
    {
      name: "function_title",
      type: "text",
      label: "Functie titel",
    },
    {
      name: "firstName",
      type: "text",
      label: "Voornaam",
      placeholder: "Voornaam",
    },
    {
      name: "lastName",
      type: "text",
      label: "Achternaam",
      placeholder: "Achternaam",
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      placeholder: "Email",
    },
    {
      name: "phoneNumber",
      type: "text",
      label: "Telefoonnummer",
      placeholder: "Telefoonnummer",
    },
    { name: "city", type: "text", label: "Stad", placeholder: "Stad" },
    { name: "address", type: "text", label: "Adres", placeholder: "Adres" },
    {
      name: "zipCode",
      type: "text",
      label: "Postcode",
      placeholder: "Postcode",
    },
  ];

  const initialValues = {
    firstName: "",
    lastName: "",
    role: "",
    profileImage: "",
    email: "",
    phoneNumber: "",
    stateRegion: "",
    city: "",
    address: "",
    zipCode: "",
    function_title: "",
    vacations: [{ fromDate: "", toDate: "" }],
    workingSchedule: {
      monday: { startTime: "", endTime: "" },
      tuesday: { startTime: "", endTime: "" },
      wednesday: { startTime: "", endTime: "" },
      thursday: { startTime: "", endTime: "" },
      friday: { startTime: "", endTime: "" },
      saturday: { startTime: "", endTime: "" },
      sunday: { startTime: "", endTime: "" },
    },
  };

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <NewUserForm
      fields={fieldConfigurations}
      toggleSwitches={toggleSwitches}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitButtonText="Wijzigingen opslaan"
      sideSteps={true}
    />
  );
};

export default AddEmployee;
