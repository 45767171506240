// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FormikInputColor.css */
.formik-input-color {
    display: flex;
    flex-direction: column;
  }
  
  .color-input {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .color-preview {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer;
  }
  
  .color-palette-icon {
    cursor: pointer;
  }
  
  .input-container {
    flex: 1 1; /* Expand to fill available space */
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .input-field {
    flex: 1 1; /* Expand to fill available space */
    padding: 6px 12px;
  }
  
  .color-picker {
    position: absolute;
    z-index: 1;
    top: 100%;
  }
  
  .reset-button {
    margin-top: 8px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .reset-button:hover {
    background-color: #ddd;
  }
  
  .postfix {
    margin-left: 8px; /* Adjust spacing as needed */
  }
  `, "",{"version":3,"sources":["webpack://./src/formik/components/css/FormikInputColor.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,SAAO,EAAE,mCAAmC;IAC5C,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,SAAO,EAAE,mCAAmC;IAC5C,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,gBAAgB,EAAE,6BAA6B;EACjD","sourcesContent":["/* FormikInputColor.css */\n.formik-input-color {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .color-input {\n    display: flex;\n    align-items: center;\n    position: relative;\n  }\n  \n  .color-preview {\n    width: 24px;\n    height: 24px;\n    border-radius: 50%;\n    margin-right: 8px;\n    cursor: pointer;\n  }\n  \n  .color-palette-icon {\n    cursor: pointer;\n  }\n  \n  .input-container {\n    flex: 1; /* Expand to fill available space */\n    display: flex;\n    align-items: center;\n    position: relative;\n  }\n  \n  .input-field {\n    flex: 1; /* Expand to fill available space */\n    padding: 6px 12px;\n  }\n  \n  .color-picker {\n    position: absolute;\n    z-index: 1;\n    top: 100%;\n  }\n  \n  .reset-button {\n    margin-top: 8px;\n    padding: 4px 8px;\n    background-color: #f0f0f0;\n    border: 1px solid #ccc;\n    cursor: pointer;\n  }\n  \n  .reset-button:hover {\n    background-color: #ddd;\n  }\n  \n  .postfix {\n    margin-left: 8px; /* Adjust spacing as needed */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
