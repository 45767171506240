// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Pagination.css */
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.pagination-controls {
    display: flex;
    align-items: center;
}

.pagination-controls span {
    margin-right: 10px;
}

.pagination-select {
    margin-right: 20px;
    padding: 5px;
}

.pagination-info {
    margin-right: 20px;
}

.pagination-button {
    margin: 0 5px;
    color: #1A3E65;
    font-size: 24px;
    /* Larger font size for bigger arrows */
    cursor: pointer;
    background: none;
    border: none;
}

.pagination-button:disabled {
    color: grey;
    cursor: default;
}

/* Add styling for hover state if needed */
.pagination-button:hover:not(:disabled) {
    color: #7a6ff0;
    /* Lighter purple shade for hover effect */
}`, "",{"version":3,"sources":["webpack://./src/css/Pagination.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,eAAe;IACf,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA,0CAA0C;AAC1C;IACI,cAAc;IACd,0CAA0C;AAC9C","sourcesContent":["/* Pagination.css */\n.pagination-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 0;\n}\n\n.pagination-controls {\n    display: flex;\n    align-items: center;\n}\n\n.pagination-controls span {\n    margin-right: 10px;\n}\n\n.pagination-select {\n    margin-right: 20px;\n    padding: 5px;\n}\n\n.pagination-info {\n    margin-right: 20px;\n}\n\n.pagination-button {\n    margin: 0 5px;\n    color: #1A3E65;\n    font-size: 24px;\n    /* Larger font size for bigger arrows */\n    cursor: pointer;\n    background: none;\n    border: none;\n}\n\n.pagination-button:disabled {\n    color: grey;\n    cursor: default;\n}\n\n/* Add styling for hover state if needed */\n.pagination-button:hover:not(:disabled) {\n    color: #7a6ff0;\n    /* Lighter purple shade for hover effect */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
