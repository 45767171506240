// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-position {
  margin-top: 20px;
  margin-left: 80px;
}

/* Style for large devices (default) */
.edit-page-position {
  margin-top: 20px;
  margin-left: 5%;
}

/* Smaller devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .edit-page-position {
    margin-left: 0;
  }
}

/* Medium devices (landscape tablets, 992px and up) */
@media (min-width: 992px) {
  .edit-page-position {
    margin-left: 3%;
  }
}

/* Large devices (laptops/desktops, 1200px and up) */
@media (min-width: 1200px) {
  .edit-page-position {
    margin-left: 4%;
  }
}

/* Extra large devices (large desktops, 1600px and up) */
@media (min-width: 1600px) {
  .edit-page-position {
    margin-left: 1%;
  }
}`, "",{"version":3,"sources":["webpack://./src/css/PrivateRoutes/PrivateRoute.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA,sCAAsC;AACtC;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA,4CAA4C;AAC5C;EACE;IACE,cAAc;EAChB;AACF;;AAEA,qDAAqD;AACrD;EACE;IACE,eAAe;EACjB;AACF;;AAEA,oDAAoD;AACpD;EACE;IACE,eAAe;EACjB;AACF;;AAEA,wDAAwD;AACxD;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".dashboard-position {\n  margin-top: 20px;\n  margin-left: 80px;\n}\n\n/* Style for large devices (default) */\n.edit-page-position {\n  margin-top: 20px;\n  margin-left: 5%;\n}\n\n/* Smaller devices (tablets, 768px and up) */\n@media (max-width: 768px) {\n  .edit-page-position {\n    margin-left: 0;\n  }\n}\n\n/* Medium devices (landscape tablets, 992px and up) */\n@media (min-width: 992px) {\n  .edit-page-position {\n    margin-left: 3%;\n  }\n}\n\n/* Large devices (laptops/desktops, 1200px and up) */\n@media (min-width: 1200px) {\n  .edit-page-position {\n    margin-left: 4%;\n  }\n}\n\n/* Extra large devices (large desktops, 1600px and up) */\n@media (min-width: 1600px) {\n  .edit-page-position {\n    margin-left: 1%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
