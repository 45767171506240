import Compressor from "compressorjs";
import {
  Field,
  Form,
  Formik,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import the Eye icons
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { GeneralLoader } from "../../components/GeneralLoader";
import FormWrapper from "../../components/PrivateRoutes/FormWrapper";
import { ProfileImageUpload } from "../../components/PrivateRoutes/ProfileImageUpload";
import RobolButton from "../../components/RobolButton";
import "../../css/PrivateRoutes/ProfileImageUpload.css";
import { auth } from "../../firebase";
import { stepsEmployeeForm } from "../../helpers/jsonData";
import {
  createUser,
  editUser,
  registerUser,
  sendWelcomeEmail,
} from "../../services/api/UserServices";
import FocusError from "./FocusError";

export const NewUserForm = ({
  fields,
  onSubmit,
  validationSchema,
  initialValues,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [userId, setUserId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleStepChange = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      setIsLoading(true);
      console.log("values", values);
      const token = await auth.currentUser.getIdToken(true);

      try {
        if (userId) {
          // Update existing user
          await editUser(token, userId, values);
        } else {
          // Register user using the external method
          await registerUser(values.email, values.password);
          await createUser(token, values);
        }

        onSubmit(values, actions);

        if (currentStep === stepsEmployeeForm.length - 1) {
          setFormValues(initialValues);
          setCurrentStep(0);
        } else {
          handleStepChange(currentStep + 1);
        }
        navigate("/dashboard"); 

        await sendWelcomeEmail(values.email);
      } catch (error) {
        console.error("Failed to create or update user", error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  if (isLoading) {
    return <GeneralLoader />;
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const renderFormStep = ({ errors, touched }) => {
    return (
      <FormikProvider value={formik}>
        <FormWrapper>
          <FocusError />
          <Form className="p-1">
            <div>
              <ProfileImageUploadWrapper name="profileImage" />
            </div>
            <div>
              {fields
                .filter((field) => field.name !== "holidays")
                .map((field) => (
                  <div key={field.name} className="mt-3">
                    {field.type === "select" ? (
                      <div>
                        <label>{field.label}</label>
                        <Field
                          as="select"
                          name={field.name}
                          className="form-control"
                        >
                          {field.selectOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <label>{field.label}</label>
                        <Field
                          name={field.name}
                          type={field.type}
                          className="form-control"
                        />
                      </div>
                    )}
                  </div>
                ))}
              <div className="mt-3">
                <label>Password</label>
                <div className="input-group">
                  <Field
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <label>Confirm Password</label>
                <div className="input-group">
                  <Field
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={toggleShowConfirmPassword}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <RobolButton
                onClick={() => formik.submitForm()}
                theme="dark"
                size="m"
                type="submit"
              >
                Medewerker aanmaken
              </RobolButton>
            </div>
          </Form>
        </FormWrapper>
      </FormikProvider>
    );
  };

  return (
    <>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values, actions);
        }}
      >
        {({ errors, touched }) => (
          <>
            <div className="row">
              <div className="col-md-6 col-8">
                <div className="row mb-3">
                  <div className="col">
                    <div className="user-card mb-3">
                      {renderFormStep({ errors, touched })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};

const ProfileImageUploadWrapper = ({ name }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleChange = (event) => {
    const file = event.target.files[0];

    new Compressor(file, {
      quality: 0.1,
      success(result) {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = () => {
          setFieldValue(name, reader.result);
        };
      },
      error(err) {
        console.error("Compression error:", err);
      },
    });
  };

  return (
    <ProfileImageUpload
      name={name}
      handleChange={handleChange}
      profileImage={values[name]}
    />
  );
};

export default NewUserForm;
