export const SidebarTitles = {
  BACK: "Terug",
  INTELLIGENCE: "Intelligentie",
  KNOWLEDGE: "Kennis",
  CONTEXT: "Context",
  GPT_MODEL: "GPT-Model",
  DESIGN: "Ontwerp",
  ACTIVATION: "Activatie",
  TEST: "Test",
  ACTIVATE: "Activate",
};

export const SidebarSubitems = {
  PERSONALITY: "Persoonlijkheid",
  COMPANY: "Bedrijf",
  RESTRICTIONS: "Restricties",
  PROMPTS: "Prompts",
  WEB_SCRAPERS: "Web Scrapers",
  DOCUMENT_SCRAPERS: "Document Scrapers",
  // LIVE_APIS: "LIVE APIs", //ROBOL v2
  // MODEL_POWER: "Model kracht",
  AVATARS: "Avatars",
  COLORS: "Kleuren",
  INTERACTIVE_TEST: "Interactieve test",
  ACTIVATE_CHATBOT: "Activate chatbot",
  LAYOUT_CONFIGURATION: "Ontwerp configuratie",
};
