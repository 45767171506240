import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LogoSvg, { CustomLogo } from "../assets/svgs/Logo";
import "../css/Header.css";
import { RobolLogo } from "../assets/svgs/SvgIcons";

export const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className={`navbar navbar-expand-lg `}>
      <div className="container">
        <Link
          to="/"
          className={`navbar-brand d-flex align-items-center ${
            isMobileMenuOpen ? "mobile-menu-open" : ""
          }`}
        >
          <div className="logo mr-3">
            <RobolLogo />
          </div>
          <span className="brand-name">ROBOL-AI.CHAT</span>
        </Link>
      </div>
    </nav>
  );
};
