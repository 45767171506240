// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 999 !important;
  color: white;
  padding: 0.5rem;
  background-color: #000000;
}

.footer-margin {
  /* Other styles... */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.footer-visible {
  opacity: 1; /* Fully visible */
}
`, "",{"version":3,"sources":["webpack://./src/css/Footer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,UAAU,EAAE,qBAAqB;EACjC,6BAA6B,EAAE,sBAAsB;AACvD;;AAEA;EACE,UAAU,EAAE,kBAAkB;AAChC","sourcesContent":["footer {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  text-align: center;\n  z-index: 999 !important;\n  color: white;\n  padding: 0.5rem;\n  background-color: #000000;\n}\n\n.footer-margin {\n  /* Other styles... */\n  opacity: 0; /* Initially hidden */\n  transition: opacity 0.3s ease; /* Smooth transition */\n}\n\n.footer-visible {\n  opacity: 1; /* Fully visible */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
