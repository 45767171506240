import { notify } from "../../formik/components/Toast";
import { ToastNotifications } from "../../helpers/Enums";
import { fetchFromAPI, patchToAPI, postToAPI } from "./ApiGeneral";

export const createUser = async (token, userData) => {
  return await postToAPI(`/api/v1/userConfig/api/addUser`, token, userData);
};

export const editUser = async (token, userData, userId) => {
  return await patchToAPI(
    `/api/v1/userConfig/api/editUser?user_id=${userId}`,
    token,
    userData
  );
};

export const getUserDetails = async (token, userId) => {
  return await fetchFromAPI(
    `/api/v1/userConfig/api/getUserDetails?user_id=${userId}`,
    token
  );
};

export const registerCompany = async (companyName, email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/registerCompany`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ companyName, email }), // Include the companyName and email in the request body
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteConfig = async (configId, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/config/${configId}`,
      {
        method: "DELETE", // DELETE method added here
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // Any other headers like Authorization if needed
        },
      }
    );

    const data = await response.json();
    console.log("test");
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateConfig = async (configId, token, updateData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/config/${configId}`,
      {
        method: "PATCH", // Method changed to PATCH
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Authorization header with token
        },
        body: JSON.stringify(updateData), // Sending the updateData object in the request body
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateConversationIsRead = async (conversationId, isReadValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/updateIsRead`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`, // Authorization header with token
        },
        body: JSON.stringify({
          id: conversationId,
          is_read: parseInt(isReadValue),
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const markConversationAsOpen = async (conversationId, isOpenValue) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/updateOpenStatus`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: conversationId,
          is_open: parseInt(isOpenValue),
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating conversation open status:", error);
  }
};

export const updateConversation = async (
  sessionId,
  updatedConversationData,
  forwarded_to,
  userEmail
) => {
  console.log("jestem w updateConversation");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/msg/api/updateConversation/${userEmail}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: sessionId,
          conversationData: updatedConversationData,
          forwarded_to: forwarded_to,
          forward: true,
        }),
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const availableCompanyUsers = async (userEmail) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/v1/businessflow/api/availableCompanyUsers?userEmail=${userEmail}`
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const allCompanyUsers = async (userEmail) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/v1/businessflow/api/allCompanyUsers?userEmail=${userEmail}`
    );

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getAvailability = async (userEmail, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/v1/userconfig/api/userIsActive?email=${userEmail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get availability");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const toggleUserActiveStatus = async (email, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/v1/userconfig/api/userToggleActive`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to toggle user active status");
    }

    const data = await response.json();
    notify(ToastNotifications.AVAILBILITY_CHANGE); // Wywołanie komunikatu toast
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getDashboardOverviewStatistics = async (userEmail, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/v1/userStatistics/api/companyUsersStats?userEmail=${userEmail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get availability");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getCompanyBots = async (userEmail, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/companyBots?userEmail=${userEmail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get companyBots");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const sendWelcomeEmail = async (email) => {
  const response = await fetch(
    `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/v1/emails/api/welcome`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, name: "Robol-AI.chat" }),
    }
  );

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export const registerUser = async (email, password) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}/api/registerUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to register user");
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getUserRole = async (token) => {
  try {
    const endpoint = "/api/v1/businessFlow/whoIs";
    const data = await fetchFromAPI(endpoint, token);

    if (data && data.role) {
      return data.role;
    } else {
      throw new Error("Role not found");
    }
  } catch (error) {
    console.error("Error fetching user role:", error);
    notify(ToastNotifications.ERROR);
    throw new Error(error.message);
  }
};

export const turnOnTheBot = async (chatbotID, token) => {
  try {
    const endpoint = `/api/v1/businessFlow/api/availability/onoff?chatbotID=${chatbotID}`;
    const response = await fetch(
      `${process.env.REACT_APP_ROBOL_AI_BACKEND}${endpoint}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to toggle bot availability");
    }
    notify(ToastNotifications.BOT_ACTIVATED);
    return await response.json();
  } catch (error) {
    notify(ToastNotifications.ERROR);
    console.error("Error toggling bot availability:", error);
    throw new Error(error.message);
  }
};
