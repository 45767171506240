import React, { useState } from "react";
import "../../css/PrivateRoutes/IntelligenceCard.css";
import "../../css/PrivateRoutes/PromptsCard.css";
import "../../css/PrivateRoutes/WebScraperCard.css";
import { FormikField } from "../../formik/components/FormikField";
import RobolButton from "../RobolButton";
import CardWrapper from "./CardWrapper";
import LiveApiTable from "./LiveApiTable";
import Tooltip from "../../helpers/Tooltip";
import { TooltipContent } from "../../helpers/Enums";

const LiveApiCard = ({
  initialValues,
  setActiveApiValues,
  currentManualStep,
  onSubmit,
}) => {
  const [liveApiList, setLiveApiList] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setActiveApiValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const renderInputFields = () => {
    return Object.keys(initialValues)
      .filter((key) => key !== "isTouched" && key !== "isSaved")
      .map((key) => (
        <div key={key} className="row my-2">
          <div className="col-md-6">
            <FormikField
              name={key}
              label={key}
              placeholder={key}
              value={initialValues[key]}
              onChange={handleInputChange}
            />
          </div>
        </div>
      ));
  };

  const handleSaveLiveApi = () => {
    const newLiveApi = {
      url: initialValues.apiUrl,
      status: "Afgehandeld",
      apiKeywords: initialValues.apiKeywords,
      apiName: initialValues.apiName,
      apiCategory: initialValues.apiCategory,
      timestamp: "30-01-2024 15:19:10",
      runned_by: "m.grzegorzyk@robol-ai.chat",
    };

    setLiveApiList([...liveApiList, newLiveApi]);
    onSubmit(initialValues);
  };

  return (
    <>
      <div className="row">
        <div className="block-margin"></div>
        <div className="col pb-5">
          <CardWrapper className="mb-2" minHeight="50vh">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h5 className="intelligence-cards-title d-flex align-items-center">
                  {currentManualStep}
                  <div className="ml-2">
                    <Tooltip toolTip={TooltipContent.LIVE_APIS} />
                  </div>
                </h5>
              </div>
            </div>
            <hr className="black" />
            <div className="row justify-content-center">
              <div className="col-12 mb-5">
                {renderInputFields()}
                <div className="row">
                  <div className="col d-flex justify-content-start mt-1">
                    <RobolButton
                      onClick={() => handleSaveLiveApi()}
                      theme="dark"
                    >
                      Live API Opslaan
                    </RobolButton>
                  </div>
                </div>
              </div>
            </div>
            <LiveApiTable liveApis={liveApiList} />
          </CardWrapper>
        </div>
      </div>
    </>
  );
};

export default LiveApiCard;
