// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ActionMenu.css */

.action-menu-container {
    position: relative;
    display: inline-block;
}

.action-menu-icon {
    color: grey;
    cursor: pointer;
}

.action-menu-icon:hover {
    color: black;
}

.action-menu-dropdown {
    opacity: 0;
    visibility: hidden;
    /* Removed the first transform declaration */
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s 0.5s;
    position: absolute;
    top: 20px;
    /* Adjusted from -40px to 20px to appear below the icon */
    right: 20px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 100;
    padding: 3px;
    /* The transform here will be overridden by .action-menu-dropdown-visible when active */
    transform: translateY(10px);
}

.action-menu-dropdown-visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.2s ease, transform 0.2s ease;
    /* Adjusted the delay */
}



.action-menu-dropdown button .icon {
    margin-right: 8px;
}

/* Add styling for hover effects */
.action-menu-dropdown button:hover {
    background-color: #f2f2f2;
}

.icon {
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/css/ActionMenu.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,4CAA4C;IAC5C,sEAAsE;IACtE,kBAAkB;IAClB,SAAS;IACT,yDAAyD;IACzD,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,uFAAuF;IACvF,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,wBAAwB;IACxB,kDAAkD;IAClD,uBAAuB;AAC3B;;;;AAIA;IACI,iBAAiB;AACrB;;AAEA,kCAAkC;AAClC;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["/* ActionMenu.css */\n\n.action-menu-container {\n    position: relative;\n    display: inline-block;\n}\n\n.action-menu-icon {\n    color: grey;\n    cursor: pointer;\n}\n\n.action-menu-icon:hover {\n    color: black;\n}\n\n.action-menu-dropdown {\n    opacity: 0;\n    visibility: hidden;\n    /* Removed the first transform declaration */\n    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s 0.5s;\n    position: absolute;\n    top: 20px;\n    /* Adjusted from -40px to 20px to appear below the icon */\n    right: 20px;\n    background-color: #fff;\n    border-radius: 5px;\n    z-index: 100;\n    padding: 3px;\n    /* The transform here will be overridden by .action-menu-dropdown-visible when active */\n    transform: translateY(10px);\n}\n\n.action-menu-dropdown-visible {\n    opacity: 1;\n    visibility: visible;\n    transform: translateY(0);\n    transition: opacity 0.2s ease, transform 0.2s ease;\n    /* Adjusted the delay */\n}\n\n\n\n.action-menu-dropdown button .icon {\n    margin-right: 8px;\n}\n\n/* Add styling for hover effects */\n.action-menu-dropdown button:hover {\n    background-color: #f2f2f2;\n}\n\n.icon {\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
