import React from "react";
import "../css/RobolButton.css";

const RobolButton = ({ disabled, children, theme, size, onClick, isSaved, className }) => {
  const buttonClass = `btn ${
    theme === "dark"
      ? "robol-btn-dark"
      : theme === "green"
      ? "robol-btn-green"
      : theme === "inputButton"
      ? "robol-btn-input"
      : theme === "underline"
      ? "robol-btn-underline"
      : "robol-btn-light"
  } ${size ? `btn-${size}` : ""} ${isSaved ? "saved" : ""}`;

  return (
    <span disabled={disabled} type='button' className={`${buttonClass} ${className}`} onClick={onClick}>
      {children}
      {isSaved && <span className='ml-2 checkmark'>&#10003;</span>}
    </span>
  );
};

export default RobolButton;
